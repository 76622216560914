import React, { useState, useEffect } from "react";
import { Avatar, Badge, Container, Icon, Nav, Navbar, Modal } from "rsuite";

import { useApp } from "../../AppStore";

import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import GQL from "./graphql";

import Account from "../account";
import Lots from "./lots";

/*
const pages = [
  {
    icon: "map-marker",
    value: "device",
    label: "Device",
  },
  {
    icon: "setting",
    value: "account",
    label: "Account",
  },
];
*/
const isMobile = window.innerWidth < 992;
const isBigScreen = window.innerWidth > 1920;

export default (props) => {
  const [
    { loggedIn, menuExpanded, globalFilters, globalUpdates, user },
    actions,
  ] = useApp();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showLotModal, setShowLotModal] = useState(false);
  const {
    data: enrolled_devices_count,
    loading: loading_enrolled_devices_count,
  } = useQuery(GQL.Queries.enrolled_devices_count);
  const {
    data: devices_in_recovery_mode,
    loading: loading_devices_in_recovery_mode,
    refetch: refetch_devices_in_recovery_mode,
  } = useQuery(GQL.Queries.devices_in_recovery_mode);
  const {
    data: geofence_count,
    loading: loading_geofence_count,
    refetch: refetch_geofence_count,
  } = useQuery(GQL.Queries.geofence_count);
  const { data: devices, loading: loading_devices } = useQuery(
    GQL.Queries.devices
  );

  useEffect(() => {
    if (loggedIn) refetch_devices_in_recovery_mode();
    if (loggedIn) refetch_geofence_count();
  }, [
    globalUpdates,
    refetch_devices_in_recovery_mode,
    refetch_geofence_count,
    loggedIn,
  ]);

  //console.log({ user });

  return (
    <Container
      //className="header"
      style={{
        paddingLeft: isBigScreen && "10%",
        paddingRight: isBigScreen && "10%",
        width: "100%",
        //backgroundColor: "#EDEDED",
        backgroundColor: "rgba(0, 0, 0, 0.0)",
      }}
    >
      <Navbar
        style={{
          width: "100%",
          //backgroundColor: "#EDEDED",
          backgroundColor: "rgba(0, 0, 0, 0.0)",
        }}
      >
        {loggedIn && (
          <Navbar.Header style={{ padding: "15px" }}>
            {menuExpanded && !isMobile ? (
              <img src="/images/logo.png" height="25px" alt="logo" />
            ) : (
              <img src="/images/logo-small.png" height="25px" alt="logo" />
            )}
          </Navbar.Header>
        )}
        {loggedIn && (
          <Navbar.Body>
            {/*
          <Nav>
            <Nav.Item
              icon={
                menuExpanded ? (
                  <Icon icon="arrow-left" />
                ) : (
                  <Icon icon="arrow-right" />
                )
              }
              onClick={() => {
                actions.setMenuExpanded(!menuExpanded);
              }}
            />
          </Nav>
            */}
            <Nav>
              <Nav.Item
                icon={<Icon icon="map-marker" style={{ color: "#3498db" }} />}
              >
                {loading_enrolled_devices_count || !enrolled_devices_count
                  ? "..."
                  : enrolled_devices_count.enrolled_devices_count.toLocaleString()}
              </Nav.Item>
              <Nav.Item
                icon={<Icon icon="medkit" style={{ color: "#1abc9c" }} />}
                onClick={() =>
                  globalFilters.includes("recovery")
                    ? actions.removeGlobalFilter("recovery")
                    : actions.addGlobalFilter("recovery")
                }
              >
                {loading_devices_in_recovery_mode ||
                !devices_in_recovery_mode ? (
                  "..."
                ) : globalFilters.includes("recovery") ? (
                  <Badge>
                    {devices_in_recovery_mode.devices_in_recovery_mode.toLocaleString()}
                  </Badge>
                ) : (
                  devices_in_recovery_mode.devices_in_recovery_mode.toLocaleString()
                )}
              </Nav.Item>
              {/*
              <Nav.Item
                icon={<Icon icon="circle-o" style={{ color: "#2ecc71" }} />}
              >
                {loading_geofence_count || !geofence_count
                  ? "..."
                  : geofence_count.geofence_count.toLocaleString()}
              </Nav.Item>
                */}
              <Nav.Item
                icon={<Icon icon="battery-1" style={{ color: "#e74c3c" }} />}
                onClick={() =>
                  globalFilters.includes("lowbattery")
                    ? actions.removeGlobalFilter("lowbattery")
                    : actions.addGlobalFilter("lowbattery")
                }
              >
                {loading_devices || !devices ? (
                  "..."
                ) : globalFilters.includes("lowbattery") ? (
                  <Badge>
                    {devices.devices
                      .filter(
                        (d) =>
                          d.last_report &&
                          d.last_report.battery_level &&
                          parseFloat(d.last_report.battery_level) < 20
                      )
                      .length.toLocaleString()}
                  </Badge>
                ) : (
                  devices.devices
                    .filter(
                      (d) =>
                        d.last_report &&
                        d.last_report.battery_level &&
                        parseFloat(d.last_report.battery_level) < 20
                    )
                    .length.toLocaleString()
                )}
              </Nav.Item>
              {/*
              pages.map((page, index) => (
                <Nav.Item
                  icon={<Icon icon={page.icon} />}
                  onClick={() => actions.setSelectedPage(page.value)}
                  active={page.value === selectedPage}
                  key={"header-nav-" + index}
                >
                  {page.label}
                </Nav.Item>
              ))*/}
            </Nav>
            <Nav pullRight>
              {user &&
                user.role_id &&
                (user.role_id === 1 || user.role_id === 2) && (
                  <Nav.Item
                    //icon={<Icon icon="map-marker" />}
                    onClick={() => setShowLotModal(true)}
                  >
                    Geofence
                  </Nav.Item>
                )}
              <Nav.Item
                icon={<Icon icon="cog" />}
                onClick={() => setShowSettingsModal(true)}
              />
              <Nav.Item
                icon={<Icon icon="sign-out" />}
                onClick={() => {
                  actions.logout();
                }}
              />
            </Nav>
          </Navbar.Body>
        )}
      </Navbar>
      <Modal show={showLotModal} size={isMobile ? "xs" : "lg"}>
        <Modal.Header onHide={() => setShowLotModal(false)}>
          <Modal.Title>
            <Icon icon="map-marker" /> Lots
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Lots />
        </Modal.Body>
      </Modal>
      <Modal show={showSettingsModal} size={isMobile ? "xs" : "lg"}>
        <Modal.Header onHide={() => setShowSettingsModal(false)}>
          <Modal.Title>
            <Icon icon="cog" /> Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Account onClose={() => setShowSettingsModal(false)} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};
