import React, { useState } from "react";
import { Container, Input, InputGroup, Icon, Tooltip, Whisper } from "rsuite";

const isMobile = window.innerWidth < 992;

export default (props) => {
  const { defaultValue, onSave, size, fontSize } = props;
  const [value, setValue] = useState(defaultValue);
  const [editValue, setEditValue] = useState(defaultValue);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Container>
      {showEdit ? (
        <InputGroup>
          <Input value={editValue} onChange={setEditValue} size={size} />
          <InputGroup.Button
            color="green"
            onClick={() => {
              setShowEdit(false);
              setValue(editValue);
              onSave(editValue);
            }}
            size={size}
            disabled={editValue === defaultValue}
          >
            <span>
              <Icon icon="save" style={{ color: "white" }} />
              {!isMobile && " Save"}
            </span>
          </InputGroup.Button>
          <InputGroup.Button
            size={size}
            appearance="primary"
            color="orange"
            onClick={() => {
              setShowEdit(false);
              setEditValue(defaultValue);
            }}
          >
            <span>
              <Icon icon="ban" style={{ color: "white" }} />
              {!isMobile && " Cancel"}
            </span>
          </InputGroup.Button>
        </InputGroup>
      ) : (
        <span onClick={() => setShowEdit(true)} style={{ cursor: "pointer" }}>
          <Whisper
            speaker={
              <Tooltip placement="right">
                <Icon icon="pencil" /> Edit
              </Tooltip>
            }
          >
            <span style={{ fontSize }}>{value}</span>
          </Whisper>
        </span>
      )}
    </Container>
  );
};
