import { last } from "lodash";
import React, { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import {
  Loader,
  Button,
  ControlLabel,
  Container,
  Form,
  FormControl,
  FormGroup,
  Input,
  InputGroup,
  Icon,
  Grid,
  Col,
  Row,
  HelpBlock,
} from "rsuite";

import GQL from "./graphql";

const Styles = {
  main: {
    fontFamily: "'nunito sans', sans-serif",
  },
  menu: {
    textAlign: "left",
    padding: "15px",
    color: "white",
    fontSize: "20px",
    fontWeight: "bold",
    cursor: "pointer",
  },
  header: {
    paddingTop: "150px",
    textAlign: "center",
    color: "white",
  },
  headerText: {
    fontSize: "52px",
    fontWeight: "bold",
  },
  headerSubText: {
    fontSize: "30px",
  },
  formHeader: {
    paddingTop: "75px",
    textAlign: "center",
    fontSize: "40px",
    fontWeight: "bold",
  },
  formSubHeader: {
    fontSize: "16px",
    paddingTop: "15px",
    textAlign: "center",
  },
  group: {
    width: "100%",
    paddingTop: "20px",
  },
  label: {
    fontSize: "16px",
  },
  input: {
    borderRadius: "1px",
  },
  submit: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  submitButton: {
    backgroundColor: "#5cafbf",
    color: "white",
    padding: "10px",
    paddingLeft: "32px",
    paddingRight: "32px",
    borderRadius: "25px",
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export default (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname2, setFirstname2] = useState("");
  const [lastname2, setLastname2] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [imei, setImei] = useState("");
  const [vin, setVin] = useState("");
  const [country, setCountry] = useState("");
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [location, setLocation] = useState("");

  const [foundImei, setFoundImei] = useState(null);

  const apolloClient = useApolloClient();

  const checkImei = async () => {
    apolloClient
      .query({
        query: GQL.Queries.valid_imei,
        variables: { imei },
      })
      .then(({ data }) => {
        //console.log({data});
        if (data.valid_imei === true) {
          setFoundImei(true);
        } else {
          setFoundImei(false);
        }
      });
  };

  const canSubmit = () => {
    let valid = true;

    if (firstname.trim().length == 0) valid = false;
    if (lastname.trim().length == 0) valid = false;
    if (address.trim().length == 0) valid = false;
    if (city.trim().length == 0) valid = false;
    if (state.trim().length == 0) valid = false;
    if (zip.trim().length == 0) valid = false;
    if (country.trim().length == 0) valid = false;
    if (email.trim().length == 0) valid = false;
    if (phone1.trim().length == 0) valid = false;
    //if (phone2.trim().length == 0) valid = false;
    //if (phone3.trim().length == 0) valid = false;
    if (imei.trim().length == 0) valid = false;
    if (foundImei !== true) valid = false;
    if (vin.trim().length == 0) valid = false;
    if (year.trim().length == 0) valid = false;
    if (make.trim().length == 0) valid = false;
    if (model.trim().length == 0) valid = false;
    if (location.trim().length == 0) valid = false;
    if (submitting) valid = false;

    return valid;
  };

  const submit = () => {
    setSubmitting(true);
    apolloClient
      .mutate({
        mutation: GQL.Mutations.register_device,
        variables: {
          firstname,
          lastname,
          firstname2,
          lastname2,
          address,
          address2,
          city,
          state,
          zip,
          country,
          email,
          //phone: `(${phone1}) ${phone2} ${phone3}`,
          phone: phone1,
          imei,
          vin,
          year,
          make,
          model,
          location,
        },
      })
      .then(({ data }) => {
        if (data.register_shield_device === true) {
          setSubmitting(false);
          setSubmitted(true);
        } else {
          setSubmitted(true);
          setError(true);
        }
      })
      .catch((err) => {
        console.error({ err });
        setSubmitted(true);
        setSubmitting(false);
        setError(true);
      });
  };

  //console.log({firstname, lastname, firstname2, lastname2, address, address2, city, state, zip, email, phone1, phone2, phone3, imei, year, make, model, location});

  return (
    <Container style={Styles.main}>
      <Container
        style={{
          height: "600px",
          backgroundImage: "url(/images/shield-bg.png)",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "center",
        }}
      >
        <Grid style={{ width: "100%" }}>
          <Row>
            <Col xs={24} style={Styles.menu}>
              REGISTER SVR
            </Col>
          </Row>
          <Row>
            <Col xs={24} style={Styles.header}>
              <div style={Styles.headerText} />
            </Col>
          </Row>
        </Grid>
      </Container>
      <Grid style={{ color: "#333" }}>
        <Row>
          <Col xs={4} />
          <Col xs={16} style={{ padding: "10px" }}>
            <Row>
              <Col xs={24} style={Styles.formHeader}>
                REGISTER YOUR DEVICE
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={Styles.formSubHeader}>
                Please complete the form below to register your SHIELD device.
                <br />
                <a
                  href="https://www.getm2m.com/s/svr-install.pdf"
                  target="_blank"
                >
                  Click here
                </a>{" "}
                for device placement suggestions.
              </Col>
            </Row>
            {submitted ? (
              <Row>
                <Col
                  xs={24}
                  style={{ paddingTop: "50px", paddingBottom: "50px" }}
                >
                  {error ? (
                    <span>
                      An unknown error has occurred. Please contact us at{" "}
                      <a href="mailto:shield@getm2m.com">SHIELD@getm2m.com</a>.
                    </span>
                  ) : (
                    "Thank you for your submission."
                  )}
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        First Name *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        value={firstname}
                        onChange={setFirstname}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Last Name *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        value={lastname}
                        onChange={setLastname}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        First Name (Optional Second Registrant)
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        value={firstname2}
                        onChange={setFirstname2}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Last Name (Optional Second Registrant)
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        value={lastname2}
                        onChange={setLastname2}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Address *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="Street"
                        value={address}
                        onChange={setAddress}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Address (Optional Line 2)
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="Suite, Apt, Etc."
                        value={address2}
                        onChange={setAddress2}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>City *</ControlLabel>
                      <Input
                        style={Styles.input}
                        value={city}
                        onChange={setCity}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>State *</ControlLabel>
                      <Input
                        style={Styles.input}
                        value={state}
                        onChange={setState}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Zip Code *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        value={zip}
                        onChange={setZip}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Country *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        value={country}
                        onChange={setCountry}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>Email *</ControlLabel>
                      <Input
                        style={Styles.input}
                        value={email}
                        onChange={setEmail}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Phone Number *
                      </ControlLabel>
                      <Input
                        name="phone1"
                        value={phone1}
                        onChange={setPhone1}
                      />
                      {/*
                <InputGroup>
                  <Input style={Styles.input} placeholder="(###)" value={phone1} onChange={setPhone1} />
                  <Input style={Styles.input} placeholder="###" value={phone2} onChange={setPhone2} />
                  <Input style={Styles.input} placeholder="####" value={phone3} onChange={setPhone3} />
                </InputGroup>                
                */}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Shield IMEI Number *
                      </ControlLabel>
                      <InputGroup>
                        <Input
                          style={Styles.input}
                          value={imei}
                          onChange={setImei}
                          onBlur={checkImei}
                        />
                        <InputGroup.Addon>
                          <Icon
                            icon={foundImei === false ? "exclamation" : "check"}
                            style={{
                              color:
                                foundImei == null
                                  ? "lightgray"
                                  : foundImei
                                  ? "green"
                                  : "red",
                            }}
                          />
                        </InputGroup.Addon>
                      </InputGroup>
                      {foundImei === false && (
                        <HelpBlock style={{ color: "red" }}>
                          Please doublecheck the IMEI!
                        </HelpBlock>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        VIN Number *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="VIN"
                        value={vin}
                        onChange={setVin}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Vehicle Information *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="Year"
                        value={year}
                        onChange={setYear}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Vehicle Information *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="Make"
                        value={make}
                        onChange={setMake}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Vehicle Information *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="Model"
                        value={model}
                        onChange={setModel}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <FormGroup style={Styles.group}>
                      <ControlLabel style={Styles.label}>
                        Purchasing Location *
                      </ControlLabel>
                      <Input
                        style={Styles.input}
                        placeholder="Dealership/Purchasing Location Name"
                        value={location}
                        onChange={setLocation}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} style={Styles.submit}>
                    <Button
                      style={Styles.submitButton}
                      disabled={!canSubmit()}
                      onClick={() => submit()}
                    >
                      {submitting ? "SUBMITTING" : "SUBMIT"}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs={24}>
                Once you complete this registration form, you will receive a
                confirmation email from SHIELD@getM2M.com summarizing your
                submission information within 24-48 business hours. If you do
                not receive a confirmation email, please email us at{" "}
                <a href="mailto:shield@getm2m.com">SHIELD@getM2M.com</a>.
              </Col>
            </Row>
          </Col>
          <Col xs={4} />
        </Row>
      </Grid>
    </Container>
  );
};
