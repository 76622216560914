import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Container,
  Icon,
  Panel,
  Loader,
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  Whisper,
  RangeSlider,
  Progress,
  ButtonGroup,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Message,
} from "rsuite";
import moment from "moment";
import ArrayToCsv from "convert-array-to-csv";
import FileSaver from "file-saver";

import L from "leaflet";
import tools from "../../lib/tools";
import EditableInput from "../../components/editableinput";
//import ModeSelect from "./modeselect";
//import Geofences from "./geofences";

import Minimap from "./minimap";

// GQL
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import GQL from "./graphql";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const LIFESPAN_REPORT_COUNT = 1825;
const isMobile = window.innerWidth < 992;
const maxReportDays = 5;
const EventTypeIcon = (props) => {
  return (
    <Whisper placement="top" speaker={<Tooltip>{props.label}</Tooltip>}>
      <Avatar
        circle
        size="xs"
        style={{
          backgroundColor: props.active === true ? "#2980B9" : "#D0D0D0",
        }}
        className="button-shadow"
      >
        <Icon
          icon={props.icon}
          style={{
            color: "white",
          }}
        />
      </Avatar>
    </Whisper>
  );
};

export default (props) => {
  //const { setSelectedDevice, selectedDevice } = props;
  const [selectedReport, setSelectedReport] = useState(null);
  const [lastReport, setLastReport] = useState(null);
  const [start, setStart] = useState(maxReportDays - 5);
  const [end, setEnd] = useState(maxReportDays + 1);
  const [reportData, setReportData] = useState(null);
  const [loadingReportData, setLoadingReportData] = useState(true);

  //console.log({ props });

  const apolloClient = useApolloClient();
  const { data, loading, refetch } = useQuery(GQL.Queries.repo_token, {
    variables: {
      token: props.match.params.token,
    },
    onCompleted: (res) => {
      //console.log({ res });
      if (
        res &&
        res.repo_token &&
        res.repo_token.device &&
        res.repo_token.device.last_report
      ) {
        let last_report = JSON.parse(res.repo_token.device.last_report);
        last_report.send_time = moment.utc(last_report.send_time).valueOf();
        //console.log({ last_report });
        setSelectedReport(last_report);
        setLastReport(last_report);

        apolloClient
          .query({
            query: GQL.Queries.device_reports,
            variables: {
              device_id: res.repo_token.device.id,
              start: moment()
                .startOf("day")
                .subtract(maxReportDays - start, "days")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss"),
              end: moment()
                .startOf("day")
                .subtract(maxReportDays - end, "days")
                .utc()
                .format("YYYY-MM-DD HH:mm:ss"),
            },
          })
          .then((res) => {
            //console.log({ res });
            if (res && res.data && res.data.device_reports) {
              setReportData(res.data.device_reports);
            }
            setLoadingReportData(false);
          });
      }
    },
  });
  //console.log({ reportData });
  /*
  const {
    data: reportData,
    loading: loadingReportData,
    refetch: refetchReportData,
  } = useQuery(GQL.Queries.device_reports, {
    variables: {
      device_id: selectedDevice.id,
      start: moment()
        .startOf("day")
        .subtract(maxReportDays - start, "days")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      end: moment()
        .startOf("day")
        .subtract(maxReportDays - end, "days")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    },
    onCompleted: (res) => {
      //setRangeDisabled(false);
    },
  });
  const {
    data: unsent_commands,
    //loading: loading_unsent_commands,
    refetch: refetch_unsent_commands,
  } = useQuery(GQL.Queries.unsent_push_commands, {
    variables: {
      device_id: selectedDevice.id,
    },
  });
  */

  const exportCsv = () => {
    //console.log(reportData.device_reports);
    /*const cleanedArray = reportData.device_reports.map(
      ({
        location,
        address,
        send_time,

        gps_time,
        event_type_id,
        location_accuracy,
      }) => {
        return {
          location: location.latitude + "," + location.longitude,
          address,
          send_time: send_time
            ? moment.unix(parseInt(send_time) / 1000).format("LLL")
            : "N/A",
          gps_time: gps_time
            ? moment.unix(parseInt(gps_time) / 1000).format("LLL")
            : "N/A",
          event_type:
            event_type_id === 2
              ? "GPS"
              : event_type_id === 9
              ? "Wifi"
              : event_type_id === 10
              ? "Cell"
              : "Other",
          accuracy: location_accuracy,
        };
      }
    );

    const csvData = new Blob([ArrayToCsv(cleanedArray)], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });

    FileSaver.saveAs(
      csvData,
      selectedDevice.name + "-" + selectedDevice.imei + "-export.csv"
    );
    */
  };

  useEffect(() => {
    //mapRef && mapRef.leafletElement && mapRef.leafletElement.invalidateSize();
  });

  //console.log({ data });

  return (
    <Container
      style={{
        padding: isMobile ? 0 : "15px",
        textAlign: "left",
      }}
    >
      <Panel
        bordered
        shaded
        style={{
          background: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(25px)",
        }}
      >
        {loading ? (
          <Loader content="Loading..." />
        ) : data && data.repo_token && data.repo_token.device ? (
          <Grid fluid>
            <Row>
              <Col xs={24} md={12}>
                {loadingReportData ? (
                  <Loader />
                ) : (
                  <Minimap
                    //report={selectedReport}
                    zoomControl={true}
                    dragging={true}
                    width="100%"
                    height={
                      isMobile ? "calc(50vh - 200px)" : "calc(100vh - 75px)"
                    }
                    coords={
                      selectedReport &&
                      selectedReport.location &&
                      selectedReport.location.latitude &&
                      selectedReport.location.longitude
                        ? {
                            lat: selectedReport.location.latitude,
                            lng: selectedReport.location.longitude,
                          }
                        : { lat: 51.505, lng: -0.09, zoom: 13 }
                    }
                    radius={
                      selectedReport && selectedReport.event_type_id
                        ? selectedReport.event_type_id === 10
                          ? parseFloat(selectedReport.location_accuracy)
                          : null
                        : null
                    }
                    showEventType
                    eventTypeId={
                      selectedReport && selectedReport.event_type_id
                        ? selectedReport.event_type_id
                        : 0 //selectedDevice.event_type_id
                    }
                    label={
                      selectedReport && selectedReport.send_time
                        ? tools.getAgoString(selectedReport.send_time)
                        : "N/A" //tools.getAgoString(selectedDevice.send_time)
                    }
                  />
                )}
              </Col>
              <Col xs={24} md={12}>
                <Grid fluid style={{ marginBottom: "10px", padding: 0 }}>
                  <Row>
                    <Col xs={12}>
                      <small style={{ color: "#aaaaaa" }}>DEVICE</small>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <small style={{ color: "#aaaaaa" }}>REPORT MODE</small>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      //onClick={() => setShowEditDevice(true)}
                      style={{ cursor: "pointer" }}
                    >
                      <span style={{ fontSize: "32px" }}>
                        {data &&
                        data.repo_token &&
                        data.repo_token.device &&
                        data.repo_token.device.name
                          ? data.repo_token.device.name
                          : data.repo_token.device.imei
                          ? data.repo_token.device.imei
                          : "DEVICE"}
                      </span>
                    </Col>
                    <Col xs={12} style={{ textAlign: "right" }}>
                      <span style={{ fontSize: "32px" }}>
                        {/*unsent_commands &&
                        unsent_commands.push_commands &&
                        unsent_commands.push_commands.length > 0 ? (
                          <Whisper
                            speaker={<Tooltip>Pending mode change</Tooltip>}
                            placement="top"
                          >
                            <Icon
                              icon="info-circle"
                              size="2x"
                              style={{ marginRight: "5px" }}
                            />
                          </Whisper>
                        ) : (
                          ""
                        )*/}
                        {data &&
                        data.repo_token &&
                        data.repo_token.device &&
                        data.repo_token.device.device_mode &&
                        data.repo_token.device.device_mode.name
                          ? data.repo_token.device.device_mode.name
                          : "N/A"}
                      </span>
                    </Col>
                  </Row>
                </Grid>
                <Container style={{ marginBottom: "5px" }}>
                  <small style={{ color: "#aaaaaa" }}>DETAILS</small>
                </Container>
                <Grid fluid>
                  <Row>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Address</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="home" style={{ color: "#2980B9" }} />
                      </Whisper>
                    </Col>
                    <Col xs={14} style={{ textAlign: "left" }}>
                      {lastReport && lastReport.address
                        ? lastReport.address
                        : "N/A"}
                    </Col>
                    <Col xs={8} style={{ textAlign: "right" }}>
                      {data &&
                      data.repo_token &&
                      data.repo_token.device &&
                      data.repo_token.device.enrolled_date
                        ? moment
                            .unix(data.repo_token.device.enrolled_date / 1000)
                            .format("ll")
                        : "N/A"}
                    </Col>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Enrolled Date</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="calendar" style={{ color: "#2980B9" }} />
                      </Whisper>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      <Whisper
                        speaker={<Tooltip>Lifespan</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="heartbeat" style={{ color: "#2980B9" }} />
                      </Whisper>
                    </Col>
                    <Col xs={11} style={{ textAlign: "left" }}>
                      {(data &&
                      data.repo_token &&
                      data.repo_token.device &&
                      data.repo_token.device.report_count
                        ? 100 -
                          parseInt(
                            (data.repo_token.device.report_count /
                              LIFESPAN_REPORT_COUNT) *
                              100
                          )
                        : 0) + "%"}
                    </Col>
                    <Col xs={11} style={{ textAlign: "right" }}>
                      {/*parseInt(data.devices[0].report_count).toLocaleString()*/}
                    </Col>
                    <Col xs={1} style={{ textAlign: "center" }}>
                      {/*<Whisper
                        speaker={<Tooltip>Report Count</Tooltip>}
                        placement="top"
                      >
                        <Icon icon="file-text" style={{ color: "#2980B9" }} />
                      </Whisper>*/}
                    </Col>
                  </Row>
                </Grid>
                <Container
                  style={{
                    padding: "8px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                  }}
                >
                  <RangeSlider
                    disabled={true}
                    min={0}
                    max={maxReportDays + 1}
                    graduated
                    tooltip={false}
                    defaultValue={[start, end]}
                    value={[start, end]}
                    onChange={(value) => {
                      //setStart(value[0]);
                      //setEnd(value[1]);
                      //refetchReportData();
                    }}
                    renderMark={(value) => {
                      if (value === maxReportDays + 1) {
                        return "Now";
                      } else if (value === maxReportDays) {
                        return "Today";
                      } else if (value === maxReportDays - 1) {
                        return "Yesterday";
                      } else {
                        return moment()
                          .subtract(maxReportDays - value, "days")
                          .format("MMM Do");
                      }
                    }}
                  />
                </Container>
                <Container>
                  {loadingReportData ? (
                    <Loader content="Loading..." />
                  ) : (
                    <Table
                      loading={loadingReportData}
                      virtualized
                      fluid
                      flex
                      data={reportData}
                      //rowHeight={75}
                      //autoHeight
                      height={
                        isMobile
                          ? parseInt(window.innerHeight / 2) - 200
                          : window.innerHeight - 265
                      }
                      onRowClick={(report) => {
                        //console.log({ report });
                        if (
                          report.location &&
                          report.location.latitude &&
                          report.location.longitude
                        ) {
                          setSelectedReport(report);
                        }
                      }}
                      style={{
                        borderRadius: "5px",
                        //border: "1px #eaeaea solid",
                      }}
                      className="button-shadow"
                    >
                      <Table.Column>
                        <Table.HeaderCell>Event</Table.HeaderCell>
                        <Table.Cell style={{ textAlign: "left" }}>
                          {(report) => {
                            return (
                              <>
                                <EventTypeIcon
                                  icon="map-marker"
                                  label="GPS Location"
                                  active={report.event_type_id === 2}
                                />{" "}
                                <EventTypeIcon
                                  icon="wifi"
                                  label="Wifi Location"
                                  active={report.event_type_id === 9}
                                />{" "}
                                <EventTypeIcon
                                  icon="signal"
                                  label="Cell Location"
                                  active={report.event_type_id === 10}
                                />
                              </>
                            );
                          }}
                        </Table.Cell>
                      </Table.Column>
                      <Table.Column>
                        <Table.HeaderCell>Signal</Table.HeaderCell>
                        <Table.Cell>
                          {(report) => {
                            if (report.event_type_id === 2) {
                              // GPS
                              const label =
                                parseInt(report.location_accuracy) +
                                " satellites";
                              const percent = Math.min(
                                parseInt(report.location_accuracy) * 9,
                                100
                              );
                              let color = "#C0392B";
                              if (percent > 75) color = "#27AE60";
                              else if (percent > 50) color = "#F39C12";
                              else if (percent > 25) color = "#D35400";

                              return (
                                <Whisper speaker={<Tooltip>{label}</Tooltip>}>
                                  <Progress.Line
                                    percent={percent}
                                    strokeColor={color}
                                    showInfo={false}
                                  />
                                </Whisper>
                              );
                            } else if (report.event_type_id === 9) {
                              // WIFI
                              const label =
                                parseInt(report.location_accuracy) +
                                " WiFi Points";
                              const percent = Math.min(
                                parseInt(report.location_accuracy) * 17,
                                100
                              );
                              let color = "#C0392B";
                              if (percent > 75) color = "#27AE60";
                              else if (percent > 50) color = "#F39C12";
                              else if (percent > 25) color = "#D35400";

                              return (
                                <Whisper speaker={<Tooltip>{label}</Tooltip>}>
                                  <Progress.Line
                                    percent={percent}
                                    strokeColor={color}
                                    showInfo={false}
                                  />
                                </Whisper>
                              );
                            } else if (report.event_type_id === 10) {
                              // LTE
                              const label =
                                parseInt(report.location_accuracy) + " meters";
                              const percent = Math.max(
                                0,
                                100 - parseInt(report.location_accuracy) / 5
                              );
                              let color = "#C0392B";
                              if (percent > 90) color = "#27AE60";
                              else if (percent > 70) color = "#F39C12";
                              else if (percent > 50) color = "#D35400";

                              return (
                                <Whisper speaker={<Tooltip>{label}</Tooltip>}>
                                  <Progress.Line
                                    percent={percent}
                                    strokeColor={color}
                                    showInfo={false}
                                  />
                                </Whisper>
                              );
                            }
                          }}
                        </Table.Cell>
                      </Table.Column>
                      <Table.Column flexGrow={2}>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell dataKey="address" />
                      </Table.Column>
                      <Table.Column flexGrow={1}>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                        <Table.Cell>
                          {(report) =>
                            moment
                              .unix(
                                parseInt(report.send_time) / 1000,
                                "YYYY-MM-DD HH:mm:ss"
                              )
                              .local()
                              .format("llll")
                          }
                        </Table.Cell>
                      </Table.Column>
                    </Table>
                  )}
                </Container>
              </Col>
            </Row>
          </Grid>
        ) : (
          <Container
            style={{
              width: "100%",
            }}
          >
            <Message
              type="error"
              showIcon
              description="Invalid or expired Token!"
            />
          </Container>
        )}
      </Panel>
      {/*
      <Modal show={showGeofencesModal} size={isMobile ? "xs" : "lg"}>
        <Modal.Header onHide={() => setShowGeofencesModal(false)}>
          <span>
            <Icon icon="circle-o" /> Geofences
          </span>
        </Modal.Header>
        <Modal.Body>
          <Geofences
            selectedDevice={selectedDevice}
            onClose={() => setShowGeofencesModal(false)}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showModeChangeModal}
        full={isMobile ? true : false}
        size="lg"
        className="warning-modal"
      >
        <ModeSelect
          selectedDevice={
            data && data.devices && data.devices.length > 0 && data.devices[0]
              ? data.devices[0]
              : null
          }
          onHide={() => setShowModeChangeModal(false)}
          refetch={refetch}
          refetch_unsent_commands={refetch_unsent_commands}
        />
      </Modal>
        */}
    </Container>
  );
};
