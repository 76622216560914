import React from "react";
import { Container } from "rsuite";

export default (props) => {
  return (
    <Container
      style={{
        paddingTop: "10px",
        paddingLeft: "10%",
        paddingRight: "10%",
        width: "100%",
        textAlign: "center",
      }}
    >
      <small>PulseWrx</small>
      {/*
      <ButtonGroup size="xs" style={{ width: "100%", marginTop: "10px" }}>
        <Button>Store</Button>
        <Button>TEST</Button>
      </ButtonGroup>
      */}
    </Container>
  );
};
