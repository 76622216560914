import moment from "moment-timezone";

export default {
  getEventType: (event_type_id) => {
    switch (event_type_id) {
      case 2:
        return "Location Update";
      case 3:
        return "Push Request";
      case 4:
        return "App Start";
      case 5:
        return "Power On";
      case 6:
        return "Power Off";
      case 7:
        return "Not Reporting";
      case 8:
        return "Check-in";
      case 9:
        return "Wifi Report";
      case 10:
        return "Cell Report";
      case 11:
        return "Low Battery";
      case 12:
        return "Battery Power On";
      case 13:
        return "Battery Power Off";
      case 14:
        return "Battery Charging";
      case 15:
        return "Battery Full";
      default:
        return "Other";
    }
  },
  getAgoString: (time) => {
    //console.log({ time });
    const seconds = moment
      .duration(moment.utc().diff(moment.unix(parseInt(time) / 1000)))
      .asSeconds();
    let value = 0;
    let unit = "";

    if (seconds < 60) {
      value = parseInt(seconds);
      unit = "second";
    } else if (seconds < 60 * 60) {
      value = parseInt(seconds / 60);
      unit = "minute";
    } else if (seconds < 60 * 60 * 60) {
      value = parseInt(seconds / 60 / 60);
      unit = "hour";
    } else {
      value = parseInt(seconds / 60 / 60 / 24);
      unit = "day";
    }

    return value + " " + unit + (value === 1 ? "" : "s") + " ago";
  },
};
