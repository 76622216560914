import gql from "graphql-tag";

export default {
  Queries: {
    lots: gql`
      query lots($account_id: Int!) {
        lots(account_id: $account_id) {
          id
          account_id
          name
          location {
            coordinates
          }
          address
          radius
        }
      }
    `,
    locate_address: gql`
      query locate_address($address: String!) {
        locate_address(address: $address)
      }
    `,
    address_rgeo: gql`
      query address_rgeo($latitude: Float!, $longitude: Float!) {
        address_rgeo(latitude: $latitude, longitude: $longitude)
      }
    `,
    enrolled_devices_count: gql`
      {
        enrolled_devices_count
      }
    `,
    reported_today: gql`
      {
        reported_today
      }
    `,
    reported_this_week: gql`
      {
        reported_this_week
      }
    `,
    total_device_reports: gql`
      {
        total_device_reports
      }
    `,
    geofence_count: gql`
      {
        geofence_count
      }
    `,
    devices: gql`
      {
        devices {
          last_report
        }
      }
    `,
    devices_in_recovery_mode: gql`
      {
        devices_in_recovery_mode
      }
    `,
    user_by_email: gql`
      query user_by_email($email: String!) {
        user_by_email(email: $email) {
          id
        }
      }
    `,
  },
  Mutations: {
    create_lot: gql`
      mutation create_lot(
        $name: String!
        $account_id: Int!
        $location: [Float!]
        $address: String!
        $radius: Int!
      ) {
        create_lot(
          name: $name
          account_id: $account_id
          location: $location
          address: $address
          radius: $radius
        )
      }
    `,
    update_lot: gql`
      mutation update_lot(
        $id: Int!
        $name: String!
        $account_id: Int!
        $location: [Float!]
        $address: String!
        $radius: Int!
      ) {
        update_lot(
          id: $id
          name: $name
          account_id: $account_id
          location: $location
          address: $address
          radius: $radius
        )
      }
    `,
    delete_lot: gql`
      mutation delete_lot($id: Int!) {
        delete_lot(id: $id)
      }
    `,
    create_push_command: gql`
      mutation create_push_command(
        $device_id: Int!
        $command_id: Int!
        $command_type: String!
        $command: String!
        $payload: String!
      ) {
        create_push_command(
          device_id: $device_id
          command_id: $command_id
          command_type: $command_type
          command: $command
          payload: $payload
        ) {
          id
        }
      }
    `,
    update_device: gql`
      mutation update_device($id: Int!, $device_mode_id: Int) {
        update_device(id: $id, device_mode_id: $device_mode_id)
      }
    `,
    forgot_password: gql`
      mutation forgot_password($email: String!) {
        forgot_password(email: $email)
      }
    `,
  },
};
