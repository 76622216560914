import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  Avatar,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Input,
  Icon,
  SelectPicker,
  Slider,
  Table,
  Tooltip,
  Whisper,
} from "rsuite";
import Minimap from "./minimap";

import GQL from "./graphql";
import { useApp } from "../../AppStore";

const feet2meters = (f) => parseInt(f * 0.3048);
const meters2miles = (meters) =>
  meters / 1609.34 < 1
    ? parseFloat(meters / 1609.34).toFixed(2)
    : parseInt(meters / 1609.34);
const miles2meters = (miles) => parseInt(miles * 1609.34);

export default (props) => {
  const [{ user }, actions] = useApp();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [radius, setRadius] = useState(miles2meters(0.25));
  const [location, setLocation] = useState({
    lat: 47.116386,
    lng: -101.299591,
  });
  const [selectedLot, setSelectedLot] = useState(null);
  const { data, loading, refetch } = useQuery(GQL.Queries.lots, {
    variables: {
      account_id: user.account_id,
    },
  });
  const [create_lot] = useMutation(GQL.Mutations.create_lot);
  const [update_lot] = useMutation(GQL.Mutations.update_lot);
  const [delete_lot] = useMutation(GQL.Mutations.delete_lot);
  const [
    locate_address,
    { data: locate_address_data, loading: locate_address_loading },
  ] = useLazyQuery(GQL.Queries.locate_address);
  const [
    address_rgeo,
    { data: address_rgeo_data, loading: address_rgeo_loading },
  ] = useLazyQuery(GQL.Queries.address_rgeo);

  const confirm_delete = (lot) => {
    if (window.confirm("Are you sure you want to delete this lot?")) {
      //console.log("DELETE");
      delete_lot({
        variables: {
          id: lot.id,
        },
      }).then((res) => refetch());
      if (selectedLot && lot.id == selectedLot.id) {
        // reset form
        setName("");
        setRadius(miles2meters(0.25));
        setLocation({
          lat: 47.116386,
          lng: -101.299591,
        });
        setSelectedLot(null);
      }
    } else {
      //console.log("NO");
    }
  };

  const create_or_update_lot = () => {
    if (selectedLot) {
      // UPDATE
      const variables = {
        id: selectedLot.id,
        name,
        location: [location.lat, location.lng],
        address,
        account_id: user.account_id,
        radius,
      };
      //console.log({ variables });
      update_lot({
        variables,
      }).then((res) => {
        refetch();
        setSelectedLot(null);
        setName("");
        setLocation({
          lat: 47.116386,
          lng: -101.299591,
        });
        setRadius(miles2meters(0.25));
      });
    } else {
      // CREATE
      const variables = {
        name,
        location: [location.lat, location.lng],
        address,
        account_id: user.account_id,
        radius,
      };
      //console.log({ variables });
      create_lot({
        variables,
      }).then((res) => {
        refetch();
        setName("");
        setLocation({
          lat: 47.116386,
          lng: -101.299591,
        });
        setRadius(miles2meters(0.25));
      });
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          //console.log({ position });
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          address_rgeo({
            variables: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
        },
        (error) => {
          console.error({ error });
        }
      );
    } else {
      //console.log("no");
    }
    if (address.length === 0) {
      address_rgeo({
        variables: {
          latitude: 47.116386,
          longitude: -101.299591,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (
      locate_address_data &&
      locate_address_data.locate_address &&
      locate_address_data.locate_address.length > 1
    ) {
      setLocation({
        lat: locate_address_data.locate_address[0],
        lng: locate_address_data.locate_address[1],
      });
    }
  }, [locate_address_data, locate_address_loading]);

  useEffect(() => {
    if (address_rgeo_data && address_rgeo_data.address_rgeo) {
      setAddress(address_rgeo_data.address_rgeo);
    }
  }, [address_rgeo_data, address_rgeo_loading]);

  //console.log({ selectedLot });

  return (
    <Container>
      <Minimap
        coords={location}
        width="100%"
        height={parseInt(window.innerHeight / 3)}
        zoomControl={true}
        dragging={true}
        radius={radius}
        label={name}
        onClick={(data) => {
          if (data && data.latlng && data.latlng.lat && data.latlng.lng) {
            setLocation(data.latlng);
            address_rgeo({
              variables: {
                latitude: data.latlng.lat,
                longitude: data.latlng.lng,
              },
            });
          }
        }}
      />
      <FormGroup style={{ width: "100%" }}>
        <InputGroup style={{ width: "100%" }}>
          <InputGroup.Addon>
            <Icon icon="tag" />
          </InputGroup.Addon>
          <Input value={name} onChange={setName} placeholder="New lot name" />
        </InputGroup>{" "}
      </FormGroup>
      <FormGroup>
        <InputGroup>
          <InputGroup.Addon>
            <Icon icon="home" />
          </InputGroup.Addon>
          <Input
            value={address}
            onChange={setAddress}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && address.length > 0) {
                locate_address({
                  variables: {
                    address,
                  },
                });
              }
            }}
            placeholder="Address"
          />
          {address && address.length > 0 && (
            <InputGroup.Addon>
              <Whisper
                speaker={<Tooltip>Locate</Tooltip>}
                trigger="hover"
                onClick={() => {
                  locate_address({
                    variables: {
                      address,
                    },
                  });
                }}
              >
                <Icon icon="target" style={{ cursor: "pointer" }} />
              </Whisper>
            </InputGroup.Addon>
          )}
        </InputGroup>
      </FormGroup>
      <FormGroup style={{ width: "100%" }}>
        <InputGroup style={{ width: "100%" }}>
          <InputGroup.Addon>
            <Icon icon="circle-o" />
          </InputGroup.Addon>
          <SelectPicker
            data={[
              {
                value: feet2meters(50),
                label: "50 feet",
              },
              {
                value: feet2meters(100),
                label: "100 feet",
              },
              {
                value: feet2meters(500),
                label: "500 feet",
              },
              {
                value: miles2meters(0.25),
                label: "1/4 mile",
              },
              {
                value: miles2meters(0.5),
                label: "1/2 mile",
              },
              {
                value: miles2meters(1),
                label: "1 mile",
              },
              {
                value: miles2meters(2),
                label: "2 miles",
              },
              {
                value: miles2meters(5),
                label: "5 miles",
              },
              {
                value: miles2meters(10),
                label: "10 miles",
              },
            ]}
            value={radius}
            onChange={setRadius}
            style={{ width: "100%" }}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup style={{ width: "100%" }}>
        {selectedLot ? (
          <ButtonToolbar style={{ width: "100%" }}>
            <ButtonGroup style={{ width: "100%" }}>
              <Button
                color="primary"
                style={{ width: "50%" }}
                onClick={() => create_or_update_lot()}
                disabled={!(name && name.length > 0)}
              >
                <Icon icon="save" /> Update Lot
              </Button>
              <Button
                style={{ width: "50%" }}
                appearance="subtle"
                onClick={() => {
                  setSelectedLot(null);
                  setName("");
                  setLocation({
                    lat: 47.116386,
                    lng: -101.299591,
                  });
                  setRadius(miles2meters(0.25));
                }}
              >
                <Icon icon="ban" /> Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ) : (
          <Button
            color="primary"
            style={{ width: "100%" }}
            onClick={() => create_or_update_lot()}
            disabled={
              !(name && name.length > 0 && address && address.length > 0)
            }
          >
            <Icon icon="plus" /> Add Lot
          </Button>
        )}
      </FormGroup>
      <Table
        //autoHeight
        height={window.innerHeight - 850}
        loading={loading}
        data={data && data.lots ? data.lots : []}
        style={{ marginTop: 20 }}
        /*
        onRowClick={(lot) => {
          setSelectedLot(lot);
          setName(lot.name);
          setLocation({
            lat: lot.location.coordinates[0],
            lng: lot.location.coordinates[1],
          });
          setRadius(lot.radius);
        }}
        */
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Lot</Table.HeaderCell>
          <Table.Cell dataKey="name">
            {(lot) => {
              return (
                <span
                  onClick={() => {
                    setSelectedLot(lot);
                    setName(lot.name);
                    setAddress(lot.address);
                    setLocation({
                      lat: lot.location.coordinates[0],
                      lng: lot.location.coordinates[1],
                    });
                    setRadius(lot.radius);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {lot.name}
                </span>
              );
            }}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.Cell dataKey="address" />
        </Table.Column>
        <Table.Column fixed={true} width={100}>
          <Table.HeaderCell style={{ textAlign: "center" }}>
            Actions
          </Table.HeaderCell>
          <Table.Cell style={{ textAlign: "center" }}>
            {(row) => {
              return (
                <Avatar
                  className="button-shadow"
                  style={{ backgroundColor: "red", cursor: "pointer" }}
                  size="xs"
                  circle
                  onClick={() => {
                    confirm_delete(row);
                  }}
                >
                  <Icon icon="trash" />
                </Avatar>
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>
    </Container>
  );
};
