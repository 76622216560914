import gql from "graphql-tag";

export default {
  Queries: {
    device_lastreport: gql`
      query devices($id: Int!) {
        devices(id: $id) {
          id
          last_report
          last_report_date
        }
      }
    `,
    device_with_lots: gql`
      query devices($id: Int!) {
        devices(id: $id) {
          id
          name
          attached_to
          imei
          device_type_id
          device_mode_id
          device_mode {
            name
          }
          enrolled_date
          report_count
          last_report
          last_report_date
          lots {
            id
            name
            location {
              coordinates
            }
            address
            radius
          }
        }
      }
    `,
    lot: gql`
      query lot($id: Int!) {
        lot(id: $id) {
          id
          name
          address
        }
      }
    `,
    lots: gql`
      query lots($account_id: Int!) {
        lots(account_id: $account_id) {
          id
          account_id
          name
          location {
            coordinates
          }
          address
          radius
        }
      }
    `,
    device: gql`
      query devices($id: Int!) {
        devices(id: $id) {
          id
          name
          attached_to
          imei
          device_type_id
          device_mode_id
          device_mode {
            name
          }
          enrolled_date
          report_count
          last_report
          last_report_date
        }
      }
    `,
    devices: gql`
      {
        devices {
          id
          name
          attached_to
          imei
          device_mode_id
          device_type_id
          device_mode {
            name
          }
          last_report_date
          last_report
        }
      }
    `,
    devices_with_last_report: gql`
      {
        devices {
          id
          name
          attached_to
          imei
          device_mode_id
          device_type_id
          device_mode {
            name
          }
          last_report
          last_report_date
        }
      }
    `,
    device_count: gql`
      {
        device_count
      }
    `,
    device_reports: gql`
      query device_reports($device_id: Int!, $start: String, $end: String) {
        device_reports(device_id: $device_id, start: $start, end: $end) {
          location {
            latitude
            longitude
          }
          address
          send_time
          signal_strength
          gps_time
          event_type_id
          location_accuracy
          lots
        }
      }
    `,
    geofences: gql`
      query geofences($device_id: Int) {
        device_geofences(device_id: $device_id) {
          id
          name
          trigger
          radius
          latitude
          longitude
          address
        }
      }
    `,
    device_modes: gql`
      query device_modes($device_type_id: Int) {
        device_modes(device_type_id: $device_type_id) {
          id
          name
          command
        }
      }
    `,
    unsent_push_commands: gql`
      query push_commands($device_id: Int) {
        push_commands(device_id: $device_id, sent: false) {
          id
        }
      }
    `,
    repo_tokens: gql`
      query repo_tokens($device_id: Int) {
        repo_tokens(device_id: $device_id) {
          id
          email
          token
          active
          expires_at
        }
      }
    `,
  },
  Mutations: {
    assign_lots: gql`
      mutation assign_lots($id: Int!, $lot_ids: [Int!]) {
        assign_lots(id: $id, lot_ids: $lot_ids)
      }
    `,
    create_push_command: gql`
      mutation create_push_command(
        $device_id: Int!
        $command_id: Int!
        $command_type: String!
        $command: String!
        $payload: String!
      ) {
        create_push_command(
          device_id: $device_id
          command_id: $command_id
          command_type: $command_type
          command: $command
          payload: $payload
        ) {
          id
        }
      }
    `,
    update_device: gql`
      mutation update_device(
        $id: Int!
        $name: String
        $attached_to: String
        $device_mode_id: Int
      ) {
        update_device(
          id: $id
          name: $name
          attached_to: $attached_to
          device_mode_id: $device_mode_id
        )
      }
    `,
    create_repo_token: gql`
      mutation create_repo_token($email: String!, $device_id: Int!) {
        create_repo_token(email: $email, device_id: $device_id)
      }
    `,
    update_repo_token: gql`
      mutation update_repo_token($id: Int!, $expires_at: String) {
        update_repo_token(id: $id, expires_at: $expires_at)
      }
    `,
    disable_repo_token: gql`
      mutation disable_repo_token($id: Int!) {
        disable_repo_token(id: $id)
      }
    `,
    enable_repo_token: gql`
      mutation enable_repo_token($id: Int!) {
        enable_repo_token(id: $id)
      }
    `,
    delete_repo_token: gql`
      mutation delete_repo_token($id: Int!) {
        delete_repo_token(id: $id)
      }
    `,
  },
};
