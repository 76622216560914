import React, { useState, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Container,
  Loader,
  Grid,
  Row,
  Col,
  Icon,
} from "rsuite";

// GQL
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";
import GQL from "./graphql";

import { useApp } from "../../AppStore";

import Minimap from "./minimap";

const isMobile = window.innerWidth < 992;

export default (props) => {
  const { selectedDevice } = props;
  const [{ user }, actions] = useApp();

  const [selectedLots, setSelectedLots] = useState([]);

  const { data, loading, refetch } = useQuery(GQL.Queries.device_with_lots, {
    variables: {
      id: selectedDevice.id,
    },
    onCompleted: (res) => {
      //console.log({ res });
      if (
        res &&
        res.devices &&
        res.devices.length > 0 &&
        res.devices[0].lots &&
        res.devices[0].lots.length > 0
      ) {
        //console.log(res.devices[0]);
        setSelectedLots(res.devices[0].lots.map((lot) => lot.id));
      }
    },
    fetchPolicy: "network-only",
  });
  const {
    data: data_lots,
    loading: loading_lots,
    refetch: refetch_lots,
  } = useQuery(GQL.Queries.lots, {
    variables: { account_id: user.account_id },
  });

  const toggleLot = (id) => {
    if (selectedLots.length < 5 && !selectedLots.includes(id)) {
      setSelectedLots([...selectedLots, id].sort());
    } else {
      setSelectedLots(selectedLots.filter((lot_id) => lot_id != id).sort());
    }
  };

  const [assign_lots] = useMutation(GQL.Mutations.assign_lots, {
    onCompleted: (res) => {
      //refetch();
      props.onClose();
    },
  });

  //console.log({ data });
  //console.log({ data_lots });

  /*
  useEffect(() => {
    if (selectedDevice && selectedDevice.id) refetch();
  }, [selectedDevice]);
  */

  return (
    <Grid style={{ width: "100%", height: window.innerHeight - 200 }}>
      {loading_lots || (loading && <Loader />)}
      {!loading &&
        !loading_lots &&
        data_lots &&
        data_lots.lots &&
        data_lots.lots.map((lot) => (
          <Row
            key={"minimap-lot-" + lot.id}
            style={{ padding: 5 }}
            onClick={() => toggleLot(lot.id)}
          >
            {/*<Col
              xs={2}
              style={{
                padding: 5,
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Icon size="3x" icon="check" style={{ color: "green" }} />
            </Col>*/}
            <Col
              xs={6}
              style={{
                padding: 5,
                alignItems: "center",
                alignContent: "center",
                textAlign: "center",
              }}
            >
              <Minimap
                radius={lot.radius}
                coords={{
                  lat: lot.location.coordinates[0],
                  lng: lot.location.coordinates[1],
                }}
                width={isMobile ? 75 : 150}
                height={isMobile ? 75 : 150}
              />
            </Col>
            <Col xs={14} style={{ padding: 5 }}>
              <div
                style={{
                  fontWeight: "bold",
                  color:
                    selectedLots &&
                    selectedLots.length > 0 &&
                    selectedLots.includes(lot.id)
                      ? "green"
                      : "lightgray",
                  fontSize: 18,
                }}
              >
                {selectedLots &&
                selectedLots.length > 0 &&
                selectedLots.includes(lot.id)
                  ? "Selected"
                  : "Unselected"}
              </div>
              <div>
                <bold>{lot.name}</bold>
              </div>
              <div>{lot.address}</div>
              <div>{lot.radius}</div>
            </Col>
          </Row>
        ))}
      <Row style={{ textAlign: "center" }}>
        <ButtonGroup>
          <Button
            size="lg"
            appearance="primary"
            color="primary"
            className="button-shadow"
            //disabled={selectedLots == data.device.lots}
            onClick={() => {
              assign_lots({
                variables: {
                  id: selectedDevice.id,
                  lot_ids: selectedLots,
                },
              });
              //props.onClose();
              //refetch();
            }}
          >
            <span>
              <Icon icon="save" /> Save
            </span>
          </Button>
          <Button
            size="lg"
            appearance="subtle"
            color="orange"
            onClick={() => {
              props.onClose();
              setSelectedLots([]);
            }}
          >
            <span>
              <Icon icon="ban" /> Cancel
            </span>
          </Button>
        </ButtonGroup>
      </Row>
    </Grid>
  );
};
