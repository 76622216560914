import React from "react";
import globalHook from "use-global-hook";
import axios from "axios";
import jscookie from "js-cookie";
import gql from "graphql-tag";
import sha1 from "sha1";

import config from "./config";

const axiosConfig = {
  headers: {
    //"Access-Control-Allow-Origin": "*",
    //"Content-Type": "application/json",
  },
  crossdomain: true,
  mode: "cors",
};

const localState = jscookie.get(config.app_cookie_name);
//const isMobile = window.innerWidth < 992;

const initialState = {
  globalFilters: [],
  menuExpanded: true,
  loggedIn: false,
  credentials: {
    username: "",
    password: "",
  },
  user: {},
  selectedGeofence: null,
  ...(localState ? JSON.parse(localState) : null),
  height: "calc(100vh - 55px)", //isMobile ? window.innerHeight - 88 + "px" : "calc(100vh - 55px)",
  selectedPage: "device",
  //height: "100vh",
  //selectedGeofence: null,
  globalUpdates: 0,
};

const saveState = (state) => {
  jscookie.set(config.app_cookie_name, JSON.stringify(state));
};

const actions = {
  triggerGlobalUpdate: (store) => {
    store.setState({
      globalUpdates: store.state.globalUpdates + 1,
    });
  },
  addGlobalFilter: (store, filter) => {
    !store.state.globalFilters.includes(filter) &&
      store.setState({
        globalFilters: [...store.state.globalFilters, filter],
      });
  },
  removeGlobalFilter: (store, filter) => {
    store.setState({
      globalFilters: store.state.globalFilters.filter((f) => f !== filter),
    });
  },
  setMenuExpanded: (store, menuExpanded) => {
    store.setState({ menuExpanded }, () => saveState(store.state));
  },
  login: (store, { username, password, client }) => {
    const mutation = gql`
      mutation login_new($username: String!, $password: String!) {
        login_new(username: $username, password: $password) {
          token
          user {
            id
            account_id
            username
            email
            name
            role_id
          }
        }
      }
    `;
    client
      .mutate({
        mutation,
        variables: {
          username,
          password: sha1(password),
        },
      })
      .then((res) => {
        //console.log({ res });
        if (res && res.data && res.data.login_new && res.data.login_new.token) {
          //console.log("new method used");
          jscookie.set(config.session_cookie_name, res.data.login_new.token);
          store.setState(
            {
              credentials: { username, password },
              user: res.data.login_new.user,
              loggedIn: true,
            },
            () => {
              saveState(store.state);
              window.location = "/";
            }
          );
        } else {
          axios
            .post(
              config.api_url + "/login",
              { username, password },
              axiosConfig
            )
            .then((res) => {
              if (res && res.data && res.data.user) {
                const user = JSON.parse(res.data.user);
                const user_session = JSON.parse(res.data.user_session);

                jscookie.set(config.session_cookie_name, user_session.token);

                store.setState(
                  {
                    credentials: { username, password },
                    user,
                    loggedIn: true,
                  },
                  () => {
                    saveState(store.state);
                    window.location = "/";
                  }
                );
              }
            })
            .catch((err) => {
              //console.log("login failed");
              store.setState({
                loginError: "Invalid Login. Please try again.",
              });
            });
        }
      });
    //store.setState({ loggedIn: true });
  },
  /*
  login: (store, { username, password }) => {
    axios
      .post(config.api_url + "/login", { username, password }, axiosConfig)
      .then((res) => {
        if (res && res.data && res.data.user) {
          const user = JSON.parse(res.data.user);
          const user_session = JSON.parse(res.data.user_session);

          jscookie.set(config.session_cookie_name, user_session.token);

          store.setState(
            {
              credentials: { username, password },
              user,
              loggedIn: true,
            },
            () => {
              saveState(store.state);
              window.location = "/";
            }
          );
        }
      });
    //store.setState({ loggedIn: true });
  },
  */
  logout: (store) => {
    jscookie.set(config.session_cookie_name, null);
    store.setState({ user: null, credentials: null, loggedIn: false }, () => {
      saveState({ user: null, credentials: null, loggedIn: false });
      window.location = "/";
    });
  },
  updateUser: (store, user) => {
    const {
      credentials: { username, password },
    } = store.state;
    axios
      .post(
        config.api_url + "/update_user",
        { username, password, ...user },
        axiosConfig
      )
      .then((res) => {
        //console.log({ res });
        if (res && res.data && res.data.user) {
          const user = JSON.parse(res.data.user);
          //console.log({ user });
          /*
          store.setState(
            {
              devices,
            },
            () => saveState(store.state)
          );
          */
        }
      });
  },
  setSelectedPage: (store, selectedPage) => {
    store.setState({ selectedPage }, () => saveState(store.state));
  },
  loadDevices: (store) => {
    //console.log(store.state.credentials);
    //console.log("loadDevices");

    const {
      credentials: { username, password },
    } = store.state;
    axios
      .post(config.api_url + "/devices", { username, password }, axiosConfig)
      .then((res) => {
        //console.log({ res });
        if (res && res.data && res.data.devices) {
          const devices = JSON.parse(res.data.devices);
          //console.log({ devices });
          store.setState(
            {
              devices: devices.map((device) => {
                //let location = { lat: 51.505, lng: -0.09 };
                let location = { lat: 0, lng: 0 };

                if (device.location) {
                  const regexedLocation = device.location.match(
                    /POINT\((-?\d+\.\d+) (-?\d+\.\d+)\)/
                  );

                  if (regexedLocation && regexedLocation.length > 2) {
                    location = {
                      lat: parseFloat(regexedLocation[1]),
                      lng: parseFloat(regexedLocation[2]),
                    };
                  }
                }

                //console.log({ location });

                return {
                  ...device,
                  location,
                  battery_level: parseFloat(device.battery_level),
                  location_accuracy: parseInt(device.location_accuracy),
                };
              }),
            },
            () => saveState(store.state)
          );
        }
      });
  },
  setSelectedGeofence: (store, selectedGeofence) => {
    store.setState({ selectedGeofence }, () => {
      saveState(store.state);
    });
  },
};

const useApp = globalHook(React, initialState, actions);

export { useApp };
