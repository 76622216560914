import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import Cookie from "js-cookie";
import "./App.css";
import "leaflet/dist/leaflet.css";

import config from "./config";

import { useApp } from "./AppStore";

import Header from "./layout/header";
import Login from "./layout/login";
import Main from "./layout/main";
import Footer from "./layout/footer";

// GQL
import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";

const token = Cookie.get(config.session_cookie_name);

function App() {
  const [{ loggedIn }, actions] = useApp();
  const apolloClient = useApolloClient();
  const [appLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    //console.log({ token });
    //console.log(window.location.pathname);
    if (window.location.pathname === "/login") {
      Cookie.set(config.session_cookie_name, null);
      setAppLoaded(true);
      //actions.logout();
      //window.location = "/logout";
    } else {
      if (!token || token === "") {
        Cookie.set(config.session_cookie_name, null);
        window.location = "/logout";
      } else {
        apolloClient
          .query({
            query: gql`
              query isValidToken($token: String!) {
                isValidToken(token: $token)
              }
            `,
            variables: {
              token,
            },
          })
          .then((isValidTokenResult) => {
            //console.log({ isValidTokenResult });
            if (
              !isValidTokenResult ||
              !isValidTokenResult.data ||
              !isValidTokenResult.data.isValidToken
            ) {
              Cookie.set(config.session_cookie_name, null);
              actions.logout();
              setAppLoaded(true);
              window.location = "/login";
            } else {
              setAppLoaded(true);
            }
          });
      }
    }
  }, [apolloClient, actions]);

  return (
    <div className="App">
      <Header />
      {!appLoaded ? <Loader /> : !loggedIn ? <Login /> : <Main />}
      {loggedIn && <Footer />}
    </div>
  );
}

export default App;
