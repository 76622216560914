import gql from "graphql-tag";

export default {
  Queries: {
    valid_imei: gql`
      query valid_imei($imei: String!) {
        valid_imei(imei: $imei)
      }
    `,
  },
  Mutations: {
    register_device: gql`
      mutation register_device(
        $firstname: String!
        $lastname: String!
        $firstname2: String
        $lastname2: String
        $address: String!
        $address2: String
        $city: String!
        $state: String!
        $zip: String!
        $email: String!
        $phone: String!
        $imei: String!
        $year: String!
        $make: String!
        $model: String!
        $location: String!
      ) {
        register_svr_device(
          firstname: $firstname
          lastname: $lastname
          firstname2: $firstname2
          lastname2: $lastname2
          address: $address
          address2: $address2
          city: $city
          state: $state
          zip: $zip
          email: $email
          phone: $phone
          imei: $imei
          year: $year
          make: $make
          model: $model
          location: $location
        )
      }
    `
  },
};
