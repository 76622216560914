import React, { useState } from "react";
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  SelectPicker,
  InputGroup,
  Icon,
  Container,
} from "rsuite";

import { useQuery, useMutation } from "@apollo/react-hooks";
import GQL from "./graphql";

import { useApp } from "../../AppStore";

export default (props) => {
  const { selectedDevice, onHide, refetch, refetch_unsent_commands } = props;
  const [, actions] = useApp();
  const { device_mode_id } = selectedDevice;
  const [newDeviceModeId, setNewDeviceModeId] = useState(
    selectedDevice.device_mode_id
  );
  const [newDeviceModeCommand, setNewDeviceModeCommand] = useState("");

  const { data } = useQuery(GQL.Queries.device_modes, {
    variables: {
      device_type_id: selectedDevice.device_type_id,
    },
  });
  const [create_push_command] = useMutation(GQL.Mutations.create_push_command, {
    onCompleted: () => {
      actions.triggerGlobalUpdate();
    },
  });
  const [update_device] = useMutation(GQL.Mutations.update_device);

  //console.log({ data });

  return (
    <Container style={{ padding: 0, margin: 0 }}>
      <Container
        style={{
          borderTopLeftRadius: "7px",
          borderTopRightRadius: "7px",
          padding: "16px",
          margin: 0,
          width: "100%",
          backgroundColor: "#e74c3c",
          alignItems: "center",
        }}
      >
        <Icon
          size="5x"
          icon="exclamation-triangle"
          style={{ color: "white" }}
        />
      </Container>
      <Container style={{ padding: "16px", alignItems: "center" }}>
        <Container style={{ fontSize: "32px" }}>CAUTION!</Container>
        <Container>
          <big>
            Changing reporting frequency will directly affect battery life. Mode
            changes will take effect on the next report.
          </big>
        </Container>
        <Container style={{ width: "100%", padding: "32px" }}>
          <InputGroup style={{ width: "100%" }}>
            <SelectPicker
              size="xl"
              style={{ width: "100%" }}
              data={
                data && data.device_modes
                  ? data.device_modes.map(({ name: label, id: value }) => ({
                      label: (value === device_mode_id ? "* " : "") + label,
                      value,
                    }))
                  : []
              }
              searchable={false}
              cleanable={false}
              defaultValue={device_mode_id}
              value={newDeviceModeId}
              onChange={(value) => {
                setNewDeviceModeId(value);
                setNewDeviceModeCommand(
                  data.device_modes.filter((option) => option.id === value)[0]
                    .command
                );
              }}
            />
          </InputGroup>
        </Container>
      </Container>
      <ButtonToolbar>
        <ButtonGroup justified>
          <Button
            color="orange"
            size="lg"
            disabled={newDeviceModeId === device_mode_id}
            onClick={() => {
              create_push_command({
                variables: {
                  device_id: selectedDevice.id,
                  command_id: 1,
                  command_type: "socket",
                  command: "CHANGE_INTERVAL",
                  payload: newDeviceModeCommand,
                },
              }).then((res) => {
                update_device({
                  variables: {
                    id: selectedDevice.id,
                    device_mode_id: newDeviceModeId,
                  },
                }).then((res) => {
                  //actions.loadDevices();
                  refetch();
                  refetch_unsent_commands();
                  onHide();
                });
              });
            }}
          >
            <span>
              <Icon icon="save" /> Change
            </span>
          </Button>
          <Button color="green" size="lg" onClick={() => onHide()}>
            <span>
              <Icon icon="ban" /> Cancel
            </span>
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Container>
  );
};
