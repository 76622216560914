import React, { useState, useEffect } from "react";
import {
  Map,
  TileLayer,
  Marker,
  Circle,
  Tooltip as MarkerTooltip,
} from "react-leaflet";
import Control from "react-leaflet-control";
import { Button, Whisper, Tooltip, Avatar, Icon, Container } from "rsuite";
//import { useApp } from "../../AppStore";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const EventTypeIcon = (props) => {
  return (
    <Whisper placement="top" speaker={<Tooltip>{props.label}</Tooltip>}>
      <Avatar
        circle
        size="sm"
        style={{
          backgroundColor: props.active === true ? "#2980B9" : "#D0D0D0",
          border: "1px solid #BBBBBB",
        }}
        className="button-shadow"
      >
        <Icon
          icon={props.icon}
          style={{
            color: "white",
          }}
        />
      </Avatar>
    </Whisper>
  );
};

export default (props) => {
  const {
    label,
    coords,
    radius,
    showEventType,
    showGoogleLink,
    eventTypeId,
    zoomControl,
    dragging,
  } = props;
  const [mapRef, setMapRef] = useState(null);
  //const [showDevices, setShowDevices] = useState(true);
  //const [{ selectedPage, devices }, actions] = useApp();

  const width = props.width ? props.width : "75px";
  const height = props.height ? props.height : "75px";

  const position = [coords.lat, coords.lng];
  const bounds =
    radius !== null && radius > 0
      ? [
          [coords.lat - radius / 111111, coords.lng - radius / 111111],
          [coords.lat + radius / 111111, coords.lng + radius / 111111],
        ]
      : [
          [coords.lat - 0.001, coords.lng - 0.001],
          [coords.lat + 0.001, coords.lng + 0.001],
        ];

  useEffect(() => {
    //L && L.map && L.map.invalidateSize && L.map.invalidateSize();
    mapRef && mapRef.leafletElement && mapRef.leafletElement.invalidateSize();
  });

  return (
    <Container
      style={{
        width,
        height,
        margin: "auto",
      }}
      {...props}
    >
      <Map
        ref={(ref) => setMapRef(ref)}
        //center={position}
        //zoom={coords.zoom}
        bounds={bounds}
        width="100%"
        style={{
          width,
          height,
          border: "1px solid #CDCDCD",
          borderRadius: "5px",
        }}
        zoomControl={zoomControl !== null ? zoomControl : false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
        dragging={dragging !== null ? dragging : false}
        attributionControl={false}
        className="button-shadow"
      >
        <TileLayer attribution="" url="//{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {radius !== null && radius > 0 && (
          <Circle center={position} radius={radius} />
        )}
        <Marker position={position}>
          <MarkerTooltip permanent>{label}</MarkerTooltip>
        </Marker>
        {/*label.length > 0 ? (
          <Control position="topleft">
            <big style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
              <Icon icon="tag" style={{ color: "#2980B9" }} /> {label}
            </big>
          </Control>
        ) : null*/}
        {showEventType === true ? (
          <Control position="bottomright">
            <EventTypeIcon
              icon="map-marker"
              label="GPS Location"
              active={eventTypeId === 2}
            />{" "}
            <EventTypeIcon
              icon="wifi"
              label="Wifi Location"
              active={eventTypeId === 9}
            />{" "}
            <EventTypeIcon
              icon="signal"
              label="Cell Location"
              active={eventTypeId === 10}
            />
          </Control>
        ) : null}
        {showGoogleLink === true ? (
          <Control position="bottomleft">
            <Button
              onClick={() => {
                //setShowGoogleMaps(!showGoogleMaps);
                let url = "https://www.google.com/maps/search/?api=1&query=";
                if (coords && coords.lat && coords.lng) {
                  url += "" + coords.lat + "," + coords.lng;
                } else {
                  url += "0,0";
                }
                window.open(url);
              }}
              appearance="subtle"
            >
              Show Google Maps
            </Button>
          </Control>
        ) : null}
      </Map>
    </Container>
  );
};
