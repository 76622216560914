import React from "react";
import { Container } from "rsuite";

import Device from "./device";
//import Account from "./account";

//import { useApp } from "../AppStore";

const isBigScreen = window.innerWidth > 1920;

export default (props) => {
  //const [{ selectedPage }] = useApp();
  //const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  //const isMobile = window.innerWidth < 992;

  /*
  const renderContent = () => {
    if (selectedPage === "device") {
      return <Device />;
    } else if (selectedPage === "account") {
      return <Account />;
    } else {
      return <div>Invalid Page.</div>;
    }
  };
  */

  return (
    <Container
      style={{
        paddingLeft: isBigScreen ? "10%" : "15px",
        paddingRight: isBigScreen ? "10%" : "15px",
      }}
    >
      {/*
      <Sidebar
        style={{
          backgroundColor: "#EDEDED",
        }}
        width={menuExpanded ? 135 : 55}
        collapsible
      >
        {!isMobile ? <Menu /> : null}
      </Sidebar>
      */}
      {/*renderContent()*/}
      <Device />
      {/*isMobile ? <MobileMenu /> : null*/}
    </Container>
  );
};
