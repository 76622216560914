import React, { useState } from "react";
import {
  //Loader,
  Container,
  Button,
  Input,
  Icon,
  SelectPicker,
  Panel,
  InputGroup,
  //Grid,
  //Row,
  //Col,
} from "rsuite";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { useApp } from "../../AppStore";
import GQL from "./graphql";

//import Stat from "./stat";

const timezones = [
  "America/Adak",
  "America/Anchorage",
  "America/Argentina/ComodRivadavia",
  "America/Atka",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Catamarca",
  "America/Chicago",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Denver",
  "America/Detroit",
  "America/Ensenada",
  "America/Fort_Wayne",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Mendoza",
  "America/Menominee",
  "America/Metlakatla",
  "America/Montreal",
  "America/New_York",
  "America/Nome",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Phoenix",
  "America/Porto_Acre",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Shiprock",
  "America/Sitka",
  "America/Virgin",
  "America/Yakutat",
  "UTC",
];
//const isMobile = window.innerWidth < 992;

export default (props) => {
  const [{ user }, actions] = useApp();

  const [username, setUsername] = useState(user.username);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [timezone, setTimezone] = useState(user.timezone);
  const [password, setPassword] = useState("");
  const [alert_email, setAlert_email] = useState("");
  const [alert_phone, setAlert_phone] = useState("");

  const {
    data: data_account,
    loading: loading_account,
    refetch: refetch_account,
  } = useQuery(GQL.Queries.account, {
    variables: {
      id: user.account_id,
    },
    onCompleted: (res) => {
      //console.log({ res });
      if (res && res.account) {
        setAlert_email(res.account.alert_email);
        setAlert_phone(res.account.alert_phone);
      }
    },
  });
  const [update_account] = useMutation(GQL.Mutations.update_account);

  //console.log({ data_account });

  /*
  const {
    data: enrolled_devices_count,
    loading: loading_enrolled_devices_count,
  } = useQuery(GQL.Queries.enrolled_devices_count);
  const { data: reported_today, loading: loading_reported_today } = useQuery(
    GQL.Queries.reported_today
  );
  const {
    data: reported_this_week,
    loading: loading_reported_this_week,
  } = useQuery(GQL.Queries.reported_this_week);
  const {
    data: total_device_reports,
    loading: loading_total_device_reports,
  } = useQuery(GQL.Queries.total_device_reports);
  const { data: geofence_count, loading: loading_geofence_count } = useQuery(
    GQL.Queries.geofence_count
  );
  const { data: devices, loading: loading_devices } = useQuery(
    GQL.Queries.devices
  );
  const {
    data: devices_in_recovery_mode,
    loading: loading_devices_in_recovery_mode,
  } = useQuery(GQL.Queries.devices_in_recovery_mode);
  */

  return (
    <Container
      style={
        {
          //paddingTop: isMobile ? 0 : "15px"
        }
      }
    >
      {/*
      <Panel
        bordered
        shaded
        style={{
          background: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(25px)",
        }}
      >
      */}
      {/*
        <Grid fluid style={{ paddingBottom: "16px" }}>
          <Row>
            <Col
              xs={24}
              md={6}
              style={{
                padding: 0,
                paddingRight: isMobile ? 0 : "4px",
                paddingBottom: isMobile ? "4px" : 0,
              }}
            >
              <Stat
                width="100%"
                backgroundImage="linear-gradient(to bottom right, #3498db, #2980b9)"
                label="ENROLLED DEVICES"
                stat={
                  loading_enrolled_devices_count ? (
                    <Loader content="Loading..." />
                  ) : (
                    enrolled_devices_count.enrolled_devices_count.toLocaleString()
                  )
                }
                icon="map-marker"
              />
            </Col>
            <Col
              xs={24}
              md={6}
              style={{
                padding: 0,
                paddingRight: isMobile ? 0 : "4px",
                paddingLeft: isMobile ? 0 : "4px",
                paddingBottom: isMobile ? "4px" : 0,
              }}
            >
              <Stat
                width="100%"
                backgroundImage="linear-gradient(to bottom right, #1abc9c, #16a085)"
                label="RECOVERY MODE"
                stat={
                  loading_devices_in_recovery_mode ? (
                    <Loader content="Loading..." />
                  ) : (
                    devices_in_recovery_mode.devices_in_recovery_mode.toLocaleString()
                  )
                }
                icon="medkit"
              />
            </Col>
            <Col
              xs={24}
              md={6}
              style={{
                padding: 0,
                paddingRight: isMobile ? 0 : "4px",
                paddingLeft: isMobile ? 0 : "4px",
                paddingBottom: isMobile ? "4px" : 0,
              }}
            >
              <Stat
                width="100%"
                backgroundImage="linear-gradient(to bottom right, #2ecc71, #27ae60)"
                label="GEOFENCES"
                stat={
                  loading_geofence_count ? (
                    <Loader content="Loading..." />
                  ) : (
                    geofence_count.geofence_count.toLocaleString()
                  )
                }
                icon="file-text"
              />
            </Col>
            <Col
              xs={24}
              md={6}
              style={{ padding: 0, paddingLeft: isMobile ? 0 : "4px" }}
            >
              <Stat
                width="100%"
                backgroundImage="linear-gradient(to bottom right, #e74c3c, #c0392b)"
                label="LOW BATTERY"
                stat={
                  loading_devices ? (
                    <Loader content="Loading..." />
                  ) : (
                    devices.devices
                      .filter(
                        (d) =>
                          d.last_report &&
                          d.last_report.battery_level &&
                          parseFloat(d.last_report.battery_level) < 20
                      )
                      .length.toLocaleString()
                  )
                }
                icon="battery-1"
              />
            </Col>
          </Row>
        </Grid>
              */}
      <InputGroup style={{ marginBottom: "10px" }}>
        <InputGroup.Addon>
          <Icon icon="user" />
        </InputGroup.Addon>
        <Input
          placeholder="Username"
          value={username}
          onChange={setUsername}
          disabled={true}
        />
      </InputGroup>
      <InputGroup style={{ marginBottom: "10px" }}>
        <InputGroup.Addon>
          <Icon icon="user-info" />
        </InputGroup.Addon>
        <Input placeholder="Name" value={name} onChange={setName} />
      </InputGroup>
      <InputGroup style={{ marginBottom: "10px" }}>
        <InputGroup.Addon>
          <Icon icon="at" />
        </InputGroup.Addon>
        <Input placeholder="Email" value={email} onChange={setEmail} />
      </InputGroup>
      {/*
      <InputGroup style={{ marginBottom: "10px" }}>
        <InputGroup.Addon>
          <Icon icon="clock-o" />
        </InputGroup.Addon>
        <SelectPicker
          cleanable={false}
          value={timezone}
          onChange={(e) => setTimezone(e.target.value)}
          data={timezones.map((tz) => ({ value: tz, label: tz }))}
          style={{ width: "100%" }}
        />
            </InputGroup>*/}
      <InputGroup style={{ marginBottom: "10px" }}>
        <InputGroup.Addon>
          <Icon icon="key" />
        </InputGroup.Addon>
        <Input
          type="password"
          label="Change Password"
          placeholder="Fill in to change password"
          value={password}
          onChange={setPassword}
          password="true"
        />
      </InputGroup>

      {user.role_id === 2 && (
        <Panel bordered header="Alerts" style={{ marginBottom: "10px" }}>
          <InputGroup style={{ marginBottom: "10px" }}>
            <InputGroup.Addon>
              <Icon icon="at" />
            </InputGroup.Addon>
            <Input
              label="Alert Email"
              placeholder="Alert Email"
              value={alert_email}
              onChange={setAlert_email}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Addon>
              <Icon icon="phone" />
            </InputGroup.Addon>
            <Input
              label="Alert Phone"
              placeholder="Alert Phone"
              value={alert_phone}
              onChange={setAlert_phone}
            />
          </InputGroup>
        </Panel>
      )}
      <Button
        appearance="primary"
        className="button-shadow"
        style={{ marginBottom: "10px" }}
        onClick={() => {
          actions.updateUser({
            name,
            email,
            timezone,
            ...(password.length > 0 ? { new_password: password } : {}),
          });

          if (user.role_id === 2) {
            update_account({
              variables: {
                id: user.account_id,
                alert_email,
                alert_phone,
              },
            }).then((res) => refetch_account());
          }
        }}
      >
        <Icon icon="save" /> Save
      </Button>
      {/*
      </Panel>
        */}
    </Container>
  );
};
