import React, { useState } from "react";
import {
  Button,
  Container,
  Grid,
  Row,
  Col,
  Table,
  Icon,
  IconButton,
  Input,
  SelectPicker,
  InputGroup,
} from "rsuite";
import axios from "axios";

import config from "../../config";
import Minimap from "./minimap";
import { useApp } from "../../AppStore";

import { useQuery } from "@apollo/react-hooks";
import GQL from "./graphql";

const DetailRow = (props) => {
  return (
    <Row style={{ padding: 0, paddingTop: "8px" }}>
      <Col xs={6} style={{ textAlign: "right", padding: "8px" }}>
        {props.label}
      </Col>
      <Col xs={2} style={{ textAlign: "center", padding: "8px" }}>
        {props.icon}
      </Col>
      <Col xs={16}>{props.value}</Col>
    </Row>
  );
};

export default (props) => {
  const { selectedDevice } = props;
  const [selectedGeofence, setSelectedGeofence] = useState(null);
  const [name, setName] = useState("");
  const [trigger, setTrigger] = useState("both");
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(39.8282);
  const [lng, setLng] = useState(-98.5795);
  const [radius, setRadius] = useState(100);
  const [searchIcon, setSearchIcon] = useState("search");
  const [
    {
      credentials: { username, password },
    },
    actions,
  ] = useApp();
  const {
    data: geofences,
    loading: loadingGeofences,
    refetch: refetchGeofences,
  } = useQuery(GQL.Queries.geofences, {
    variables: {
      device_id: selectedDevice.id,
    },
  });

  const deleteGeofence = (id) => {
    axios
      .post(config.api_url + "/delete_geofence", { username, password, id })
      .then((res) => {
        refetchGeofences();
      });
  };
  const saveOrCreateGeofence = () => {
    if (selectedGeofence && selectedGeofence.id) {
      // save
      axios
        .post(config.api_url + "/update_geofence", {
          username,
          password,
          id: selectedGeofence.id,
          name,
          latitude: lat,
          longitude: lng,
          address,
          radius,
          trigger,
        })
        .then((res) => {
          setSelectedGeofence(null);
          refetchGeofences();
        });
    } else {
      // create
      axios
        .post(config.api_url + "/create_geofence", {
          username,
          password,
          device_id: selectedDevice.id,
          name,
          latitude: lat,
          longitude: lng,
          address,
          radius,
          trigger,
        })
        .then((res) => {
          setSelectedGeofence(null);
          refetchGeofences();
          actions.triggerGlobalUpdate();
        });
    }
  };
  const geocode = () => {
    axios
      .post(
        config.api_url + "/geocode",
        { username, password, address }
        //axiosConfig
      )
      .then((res) => {
        //console.log({ res });
        if (res && res.data && res.data.location) {
          const location = JSON.parse(res.data.location);
          setLat(location.lat);
          setLng(location.lng);
          setSearchIcon("check");
          setTimeout(() => {
            setSearchIcon("search");
          }, 1000);
        }
      });
  };

  //console.log({ geofences });

  return (
    <Grid fluid>
      <Row>
        <Col xs={24} md={12}>
          <Minimap
            label={name}
            coords={{
              lat,
              lng,
            }}
            radius={radius}
            width="100%"
            height="25vh"
          />
          <Grid fluid>
            <DetailRow
              label="Name"
              value={
                <Input
                  value={name}
                  onChange={(name) => {
                    setName(name);
                    /*
                  actions.setSelectedGeofence({
                    ...globalGeofence,
                    name,
                  });
                  */
                  }}
                />
              }
              icon={<Icon icon="tag" style={{ color: "#2980B9" }} />}
            />
            <DetailRow
              label="Trigger"
              value={
                <SelectPicker
                  style={{ width: "100%" }}
                  cleanable={false}
                  searchable={false}
                  data={[
                    {
                      label: "In / Out",
                      value: "both",
                    },
                    {
                      label: "Geofence In",
                      value: "in",
                    },
                    {
                      label: "Geofence Out",
                      value: "out",
                    },
                  ]}
                  value={trigger}
                  onChange={setTrigger}
                />
              }
              icon={<Icon icon="bell" style={{ color: "#2980B9" }} />}
            />
            <DetailRow
              label="Radius"
              value={
                <SelectPicker
                  style={{ width: "100%" }}
                  cleanable={false}
                  searchable={false}
                  data={[
                    {
                      label: "50 ft",
                      value: 15.24,
                    },
                    {
                      label: "100 ft",
                      value: 30.48,
                    },
                    {
                      label: "500 ft",
                      value: 152.4,
                    },
                    {
                      label: "1000 ft",
                      value: 304.8,
                    },
                    {
                      label: "1/2 Mile",
                      value: 804.67,
                    },
                    {
                      label: "1 Mile",
                      value: 1609.34,
                    },
                  ]}
                  value={radius}
                  onChange={setRadius}
                />
              }
              icon={<Icon icon="circle-o" style={{ color: "#2980B9" }} />}
            />
            <DetailRow
              label="Address"
              value={
                <InputGroup>
                  <Input
                    value={address}
                    onChange={(address) => {
                      setAddress(address);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        geocode();
                      }
                    }}
                    //hint="Type in an address and press enter to search"
                  />
                  <InputGroup.Button
                    color="green"
                    className="button-shadow"
                    onClick={() => geocode()}
                  >
                    <Icon icon={searchIcon} />
                  </InputGroup.Button>
                </InputGroup>
              }
              icon={<Icon icon="home" style={{ color: "#2980B9" }} />}
            />
            <DetailRow
              label=""
              value={
                <Button
                  appearance="primary"
                  className="button-shadow"
                  onClick={() => {
                    saveOrCreateGeofence();
                  }}
                >
                  <Icon
                    icon={
                      !selectedGeofence || !selectedGeofence.id
                        ? "plus"
                        : "save"
                    }
                  />{" "}
                  {!selectedGeofence || !selectedGeofence.id ? "Add" : "Save"}
                </Button>
              }
              icon={""}
            />
          </Grid>
        </Col>
        <Col xs={24} md={12}>
          <Table
            loading={loadingGeofences}
            fluid
            data={
              !loadingGeofences && geofences && geofences.device_geofences
                ? geofences.device_geofences
                : []
            }
            rowHeight={75}
            height={parseInt(window.innerHeight / 2) - 155}
            virtualized={true}
            onRowClick={(geofence) => {
              setSelectedGeofence(geofence);
              setName(geofence.name);
              setLat(geofence.latitude);
              setLng(geofence.longitude);
              setRadius(geofence.radius);
              setAddress(geofence.address);
            }}
            className="button-shadow"
            style={{ borderRadius: "5px", marginBottom: "10px" }}
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Geofence</Table.HeaderCell>
              <Table.Cell>
                {(geofence) => (
                  <Minimap
                    coords={{
                      lat: geofence.latitude,
                      lng: geofence.longitude,
                      zoom: 13,
                    }}
                    width="45px"
                    height="45px"
                    radius={geofence.radius}
                  />
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Trigger</Table.HeaderCell>
              <Table.Cell dataKey="trigger" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.Cell>
                {(geofence) => (
                  <IconButton
                    appearance="primary"
                    className="button-shadow"
                    icon={<Icon icon="trash" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        window.confirm(
                          "Are you sure you want to delete this geofence?"
                        ) === true
                      ) {
                        deleteGeofence(geofence.id);
                      }
                    }}
                  />
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
          <Container style={{ alignItems: "flex-end" }}>
            <Button
              appearance="primary"
              className="button-shadow"
              disabled={!selectedGeofence || !selectedGeofence.id}
              onClick={() => {
                setSelectedGeofence(null);
                setName("");
                setRadius(0);
                setAddress("");
                setLat(0);
                setLng(0);
              }}
            >
              <span>
                <Icon icon="plus" /> New Geofence
              </span>
            </Button>
          </Container>
        </Col>
      </Row>
    </Grid>
  );
};
