import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  FormGroup,
  Panel,
  InputGroup,
  Input,
  Icon,
  Modal,
  ButtonToolbar,
  Notification,
} from "rsuite";
import Cookie from "js-cookie";

// GQL
import { useLazyQuery, useApolloClient } from "@apollo/react-hooks";
import GQL from "./graphql";

import { useApp } from "../AppStore";

export default (props) => {
  const [, actions] = useApp();
  const [popupSeen, setPopupSeen] = useState(
    Cookie.get("track-pulsewrx-popup-seen")
  );
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [imei, setImei] = useState("");
  const [company, setCompany] = useState("");
  const [confirm, setConfirm] = useState("");
  const client = useApolloClient();
  const [userByEmail, { data: user_by_email, loading: loading_user_by_email }] =
    useLazyQuery(GQL.Queries.user_by_email, {
      onCompleted: (res) => {
        //console.log({ res });
        if (res && res.user_by_email && res.user_by_email.id) {
          //console.log("user found");
          client
            .mutate({
              mutation: GQL.Mutations.forgot_password,
              variables: {
                email,
              },
            })
            .then((res) => {
              //console.log({ res });
            });
        } else {
          //console.log("user not found");
        }
      },
    });

  const showSmallPopup = () => {
    Notification.info({
      onClose: () => showSmallPopup(),
      title: (
        <span>
          Stay Miles Ahead with Shield
          <Button
            appearance="link"
            onClick={() => {
              Notification.closeAll();
              setTimeout(() => showFullPopup(), 100);
            }}
          >
            Learn more
          </Button>
        </span>
      ),
      duration: 600000,
    });
  };
  const showFullPopup = () => {
    Notification.info({
      title: "Stay Miles Ahead with Shield",
      duration: 600000,
      onClose: () => {
        Cookie.set("track-pulsewrx-popup-seen", "true");
        showSmallPopup();
      },
      description: (
        <div>
          Shield’s magnetic mount keeps us miles ahead.
          <br />
          Tip: Make Shield’s magnet stronger by removing the rubber backing on
          the back of the device.
        </div>
      ),
    });
  };

  useEffect(() => {
    if (!popupSeen) {
      showFullPopup();
    } else {
      showSmallPopup();
    }
  }, []);

  return (
    <Container
      style={{
        width: "100%",
        paddingTop: "64px",
        radius: "5px",
        placeItems: "center",
      }}
    >
      <Panel
        shaded
        //className="login"
        style={{
          width: Math.min(window.innerWidth, 480) + "px",
          background: "rgba(255, 255, 255, 0.6)",
          backdropFilter: "blur(5px)",
          margin: "auto",
        }}
      >
        <Container style={{ placeItems: "center", padding: "15px" }}>
          <img src="/images/logo.png" width="250px" alt="logo" />
        </Container>
        <Container style={{ padding: "5px" }}>
          <big>
            <strong>Welcome to Pulsewrx Asset Tracking</strong>
          </big>
        </Container>
        <Container style={{ padding: "5px" }}>
          (Tracking Built for You)
        </Container>
        <Container>
          <Form style={{ width: "100%" }}>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="user" />
                </InputGroup.Addon>
                <Input
                  placeholder="Username"
                  value={username}
                  onChange={setUsername}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="key" />
                </InputGroup.Addon>
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={setPassword}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ButtonGroup style={{ width: "100%" }}>
                <Button
                  className="button-shadow"
                  appearance="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    actions.login({ username, password, client });
                  }}
                >
                  <Icon icon="sign-in" /> Login
                </Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button
                  appearance="link"
                  onClick={() => setShowForgotPassword(true)}
                >
                  Forgot Password
                </Button>
                <Button
                  appearance="link"
                  onClick={() => setShowCreateAccount(true)}
                >
                  Create a New Account
                </Button>
              </ButtonGroup>
            </FormGroup>
          </Form>
        </Container>
      </Panel>
      <Button appearance="link" onClick={() => setShowTerms(true)}>
        Pulsewrx Terms of Use
      </Button>
      <Modal show={showForgotPassword}>
        <Modal.Header onHide={() => setShowForgotPassword(false)}>
          <Modal.Title>Reset your password.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="at" />
                </InputGroup.Addon>
                <Input placeholder="E-mail" value={email} onChange={setEmail} />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance="primary"
                color="green"
                disabled={email.length < 1}
                onClick={() => {
                  userByEmail({ variables: { email } });
                }}
              >
                Submit
              </Button>
              <Button
                appearance="subtle"
                color="orange"
                onClick={() => setShowForgotPassword(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
      <Modal show={showTerms} size="lg">
        <Modal.Header onHide={() => setShowTerms(false)}>
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Panel>
            {`BY CLICKING BELOW TO ACCEPT THESE PORTAL TERMS OF USE (THE "`}
            <u>PORTAL AGREEMENT</u>
            {`"), THE UNDERSIGNED PURCHASER ("`}
            <u>PURCHASER</u>
            {`") HEREBY ENTERS INTO AN AGREEMENT WITH PULSEWRX, INC., AN OHIO CORPORATION (THE “COMPANY”), IN CONNECTION WITH THE PRODUCT (AS DEFINED BELOW) PREVIOUSLY PURCHASED BY PURCHASER AND FOR THE PURCHASE OF THE SERVICES, MORE PARTICULARLY DESCRIBED BELOW (“SERVICES”).`}
          </Panel>
          <Panel>
            {`Purchaser acknowledges that all information gathered by or provided to the Company from Purchaser (or any affiliate or representative of Purchaser) will be subject to the terms and conditions of this Portal Agreement, the Company’s Website Terms of Use found here: `}
            <a href="https://www.pulsewrx.com" target="_blank">
              www.pulsewrx.com
            </a>
            {` and the Company’s Privacy Policy found here: `}
            <a href="https://www.pulsewrx.com" target="_blank">
              www.pulsewrx.com
            </a>
            {`.`}
          </Panel>
          <Panel>{`Purchaser acknowledges and understands that (i) data collected by the GPS Tracking Device, including Flex GPS Device, Apex GPS Device and others as they come available to the Portal website (the “Product”) while out of coverage or without calling connectivity, including, without limitation, disruption in connectivity due to weather or satellite outages, may not be stored and retrieved by third parties until coverage or calling connectivity is restored; (ii) data collected by the Product is not provided in real time and delays in receipt of data are normal;  (iii) data is temporarily (and not permanently) stored and accessible on the Company’s server; (iv) although portions of the Product (including the Services) are encrypted, the Product (including the Services) are capable of being intercepted without knowledge of or permission from the Company by third parties; (v) the Company is not responsible for the terms, conditions or obligations arising from agreements between the Purchaser and any cellular network or airtime provider in connection with the Product or Services; (vi) the Purchaser may not be able to cancel agreements between the Purchaser and its cellular network or airtime provider upon commercially reasonable terms or at all; (vii) the Company assumes no responsibility for improper storage of data or information or delivery of messages; (viii) the Company assumes no responsibility for the improper use or analysis by the police or other similar personnel of any information or data generated by the Product; (ix) the Company retains the rights to anonymous summary data analysis; (x) the Company will not warrant the battery life of the device beyond its original years purchased; and (xi) the Company will not warrant the battery life of the device if the interval of the devices is changed from the original purchased length of time.`}</Panel>
          <Panel>{`The Purchaser, the Product and the Services are dependent on the coverage and calling areas of cellular networks owned and operated by third parties. Coverage and calling areas are approximate and do not cover significant portions of North America. Actual coverage and operation of the Product depends on a variety of factors, including, without limitation, system availability and capacity, system and equipment upgrades, repairs, maintenance, modifications and relocation, Purchaser’s equipment, terrain, signal strength, structural conditions, weather and atmospheric conditions, governmental regulations, suspected fraudulent activities, acts of God and other conditions beyond the Company’s reasonable control. The Purchaser acknowledges that the Company is not be responsible for limits in coverage or performance degradation due to any such conditions. The Purchaser acknowledges that coverage and calling areas may be interrupted, halted, or curtailed or the quality of the transmission may be diminished at any time. Further, the Product and the Services are dependent upon the availability of the Internet, which is owned and operated by and accessed through third parties.`}</Panel>
          <Panel>{`PURCHASER’S USE OF THE CUSTOMER PORTAL, THE PRODUCT AND ANY SERVICES IS AT PURCHASER’S OWN RISK. THE PRODUCT, THE PORTAL, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL AND THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND OF FITNESS FOR A PARTICULAR PURPOSE. NOTHING HEREIN SHALL BE CONSTRUED AS PROVIDING OR INTENDING TO PROVIDE A MANUFACTURER’S WARRANTY TO PURCHASER OR ITS AFFILIATES OR ITS OR THEIR CUSTOMERS, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, MANAGERS OR MEMBERS.`}</Panel>
          <Panel>{`IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR ITS OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, MANAGERS OR MEMBERS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH PURCHASER’S USE, OR INABILITY TO USE, THE PRODUCT, THE CUSTOMER PORTAL, THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL OR WEBSITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES.`}</Panel>
          <Panel>{`Notwithstanding anything else in this Portal Agreement, no default, delay or failure to perform on the part of the Company shall be considered a breach of this Agreement if such default, delay or failure to perform is shown to be due to causes beyond the reasonable control of the Company, including, without limitation, failure of a 4G LTE or cellular network or the Internet network, strikes, lockouts or other labor disputes, work stoppages, riots, insurrections, civil disturbances, actions or inactions of governmental authorities or suppliers, epidemics, war, orders or acts of military or civil authority, embargoes, vandalism,  terrorism, weather, fire, explosion, earthquakes, acts of God or the public enemy, nuclear disasters, or default of a common carrier.`}</Panel>
          <Panel>
            {`All matters relating to the `}
            <a href="https://www.pulsewrx.com" target="_blank">
              www.pulsewrx.com
            </a>
            {` this Portal Agreement and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Nevada without giving effect to any choice or conflict of law provision or rule (whether of the State of Nevada or any other jurisdiction).`}
          </Panel>
          <Panel>
            {`Any legal suit, action or proceeding arising out of, or related to, the `}
            <a href="https://www.pulsewrx.com" target="_blank">
              www.pulsewrx.com
            </a>
            {` or this Portal Agreement shall be instituted exclusively in the federal courts of the United States or the courts of the State of Ohio in each case located in the County of Franklin although the Company retains the right to bring any suit, action or proceeding against the Purchaser relating to this Portal Agreement in your state or country of residence or any other relevant state or country. The Purchaser waives any and all objections to the exercise of jurisdiction over the Purchaser by such courts and to venue in such courts.`}
          </Panel>
          <Panel>{`ANY CAUSE OF ACTION OR CLAIM PURCHASER MAY HAVE ARISING OUT OF OR RELATING TO THIS PORTAL AGREEMENT MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.`}</Panel>
          <Panel>{`Any unresolved controversy or claim arising out of or relating to this Portal Agreement shall first be submitted to arbitration by one arbitrator mutually agreed upon by the parties, and if no agreement can be reached within thirty (30) days after names of potential arbitrators have been proposed by the American Arbitration Association (the “AAA”), then by one arbitrator having reasonable experience in transactions of the type provided for in this Agreement and who is chosen by the AAA.  The arbitration shall take place in Ohio, in accordance with the AAA rules then in effect, and judgment upon any award rendered in such arbitration will be binding and may be entered in any court having jurisdiction thereof. There shall be limited discovery prior to the arbitration hearing as follows: (a) exchange of witness lists and copies of documentary evidence and documents relating to or arising out of the issues to be arbitrated, (b) depositions of all party witnesses and (c) such other depositions as may be allowed by the arbitrators upon a showing of good cause. Depositions shall be conducted in accordance with the Ohio Code of Civil Procedure, the arbitrator shall be required to provide in writing to the parties the basis for the award or order of such arbitrator, and a court reporter shall record all hearings, with such record constituting the official transcript of such proceedings.`}</Panel>
          <Panel>{`For purposes of this Portal Agreement, the term “Services” shall mean a website that allows the user to view past and current locations of their purchased GPS devices, while providing the user the opportunity and ability to change the reporting frequency and time thereby providing for additional location based data attributable to their purchased GPS devises.`}</Panel>
          <Panel>{`BY CLICKING BELOW, PURCHASER ACKNOWLEDGES THAT PURCHASER HAS READ THIS PORTAL AGREEMENT, THE WEBSITE TERMS OF USE AND THE PRIVACY POLICY, AND UNDERSTANDS AND AGREES TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS COLLECTIVELY CONTAINED HEREIN AND THEREIN.`}</Panel>
          <Panel>
            <Button
              appearance="primary"
              color="green"
              onClick={() => {
                localStorage.setItem(
                  "track-pulsewrx-com-terms-accepted",
                  "true"
                );
                setShowTerms(false);
              }}
            >
              Accept
            </Button>
          </Panel>
        </Modal.Body>
      </Modal>
      <Modal show={showCreateAccount}>
        <Modal.Header onHide={() => setShowCreateAccount(false)}>
          <Modal.Title>Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="tag" />
                </InputGroup.Addon>
                <Input
                  placeholder="Name"
                  value={company}
                  onChange={setCompany}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="tag" />
                </InputGroup.Addon>
                <Input placeholder="IMEI" value={imei} onChange={setImei} />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="at" />
                </InputGroup.Addon>
                <Input placeholder="E-mail" value={email} onChange={setEmail} />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="key" />
                </InputGroup.Addon>
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={setPassword}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup style={{ width: "100%" }}>
                <InputGroup.Addon>
                  <Icon icon="key" />
                </InputGroup.Addon>
                <Input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirm}
                  onChange={setConfirm}
                />
              </InputGroup>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                appearance="primary"
                color="green"
                //disabled={email.length < 1}
                onClick={() => {
                  // TODO: Create account and register device
                }}
              >
                Submit
              </Button>
              <Button
                appearance="subtle"
                color="orange"
                onClick={() => setShowCreateAccount(false)}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
