import gql from "graphql-tag";

export default {
  Queries: {
    repo_token: gql`
      query repo_token($token: String!) {
        repo_token(token: $token) {
          email
          device_id
          active
          expires_at
          device {
            id
            name
            attached_to
            imei
            device_type_id
            device_mode_id
            device_mode {
              name
            }
            enrolled_date
            report_count
            last_report
            last_report_date
          }
        }
      }
    `,
    device_reports: gql`
      query device_reports($device_id: Int!, $start: String, $end: String) {
        device_reports(device_id: $device_id, start: $start, end: $end) {
          location {
            latitude
            longitude
          }
          address
          send_time
          signal_strength
          gps_time
          event_type_id
          location_accuracy
        }
      }
    `,
  },
  Mutations: {
    update_device: gql`
      mutation update_device(
        $id: Int!
        $name: String
        $attached_to: String
        $device_mode_id: Int
      ) {
        update_device(
          id: $id
          name: $name
          attached_to: $attached_to
          device_mode_id: $device_mode_id
        )
      }
    `,
  },
};
