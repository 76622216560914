import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Cookie from "js-cookie";
import "./index.css";
import App from "./App";
import RegisterSVR from "./layout/registersvr";
import RegisterShield from "./layout/registershield";
import Repo from "./layout/repo";
import { useApp } from "./AppStore";
import * as serviceWorker from "./serviceWorker";
import "./App.less";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jscookie from "js-cookie";
import config from "./config";

const httpLink = createHttpLink({
  uri: config.gql_url,
});

//console.log(Cookie.get(config.session_cookie_name));

const authLink = setContext(async (_, { headers }) => {
  const token = Cookie.get(config.session_cookie_name);

  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const Logout = (props) => {
  const [, actions] = useApp();
  useEffect(() => {
    actions.logout();
    jscookie.set(config.session_cookie_name, null);
    window.location = "/login";
  }, [actions]);

  return <div>Logging out</div>;
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route exact path={["/repo/:token"]} component={Repo} />
        <Route exact path={["/registersvr"]} component={RegisterSVR} />
        <Route exact path={["/registershield"]} component={RegisterShield} />
        <Route exact path={["/", "/login"]} component={App} />
        <Route exact path={["/logout"]} component={Logout} />
      </Switch>
    </Router>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
