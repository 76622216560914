import gql from "graphql-tag";

export default {
  Queries: {
    account: gql`
      query account($id: Int!) {
        account(id: $id) {
          id
          alert_email
          alert_phone
        }
      }
    `,
    enrolled_devices_count: gql`
      {
        enrolled_devices_count
      }
    `,
    reported_today: gql`
      {
        reported_today
      }
    `,
    reported_this_week: gql`
      {
        reported_this_week
      }
    `,
    total_device_reports: gql`
      {
        total_device_reports
      }
    `,
    geofence_count: gql`
      {
        geofence_count
      }
    `,
    devices: gql`
      {
        devices {
          last_report
        }
      }
    `,
    devices_in_recovery_mode: gql`
      {
        devices_in_recovery_mode
      }
    `,
  },
  Mutations: {
    update_account: gql`
      mutation update_account(
        $id: Int!
        $alert_email: String
        $alert_phone: String
      ) {
        update_account(
          id: $id
          alert_phone: $alert_phone
          alert_email: $alert_email
        )
      }
    `,
    create_push_command: gql`
      mutation create_push_command(
        $device_id: Int!
        $command_id: Int!
        $command_type: String!
        $command: String!
        $payload: String!
      ) {
        create_push_command(
          device_id: $device_id
          command_id: $command_id
          command_type: $command_type
          command: $command
          payload: $payload
        ) {
          id
        }
      }
    `,
    update_device: gql`
      mutation update_device($id: Int!, $device_mode_id: Int) {
        update_device(id: $id, device_mode_id: $device_mode_id)
      }
    `,
  },
};
